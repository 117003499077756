import {
  ENDPOINT
} from '../config';
import $ from 'jquery';
// eslint-disable-next-line
import validate from 'jquery-validation';

import toastr from 'toastr';

$(document).ready(function () {
  const $classroomPasswordReset = $('[data-selector="classroom-password-reset"]');
  if (!$classroomPasswordReset.length) return;

  const submitBtn = '[data-selector="classroom-password-reset-submit"]';
  const $passwordResetForm = $('[data-selector="password-reset-form"]');

  const $passwordResetSuccess = $('[data-selector="classroom-password-reset-success"]');
  const passwordResetClose = '[data-selector="classroom-password-reset-close"]';

  const pathNameArr = window.location.pathname.split('/');
  const token = pathNameArr[pathNameArr.length - 2];
  const uidb64 = pathNameArr[pathNameArr.length - 3];
  const reacptchaAction = 'classroom_reset_password';

  const loadingClassName = '_loading';

  const resetPassword = (captchaToken) => {
    const password = $('#password').val();

    $.ajax({
      url: ENDPOINT.USER_RESET_PASSWORD,
      type: 'PUT',
      contentType: 'application/json',
      data: JSON.stringify({
        captcha_action: reacptchaAction,
        captcha_response_token: captchaToken,
        uidb64: uidb64,
        token: token,
        password: password
      }),
      // eslint-disable-next-line
      success: (response) => {
        $passwordResetSuccess.show();
        $passwordResetForm.hide();
        $(submitBtn).removeClass(loadingClassName);
      },
      error: () => {
        $(submitBtn).removeClass(loadingClassName);
        toastr.warning('Something went wrong, please contact us');
      }
    });
  };

  jQuery($passwordResetForm).validate({
    rules: {
      password: {
        minlength: 5
      },
      password_repeat: {
        minlength: 5,
        equalTo: '#password'
      }
    },
    errorClass: 'form__validation-text',
  });

  $(document)
    .on('click', passwordResetClose, () => {
      window.location = '/classroom/';
    })
    .on('click', submitBtn, () => {
      if ($passwordResetForm.valid()) {
        $(submitBtn).addClass(loadingClassName);

        grecaptcha.ready(function () {
          grecaptcha.execute(window.RECAPTCHA_SITE_KEY, {action: reacptchaAction})
            .then(function (captchaToken) {
              resetPassword(captchaToken);
            });
        });
      }
    });
});
