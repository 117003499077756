import {
  ACCOUNT_TYPES,
  ENDPOINT,
  GET_PARAMS,
  ERROR_CODES,
  POST_HOG
} from '../config';

import {
  getUrlParam,
  toggleButtonLoading,
} from '../utils';

import posthog from 'posthog-js';
posthog.init(POST_HOG.PROJECT_ID.CLASSROOM, { api_host: 'https://us.posthog.com' });

$(document).ready(function () {
  const $joinClassroom = $('[data-selector="join-classroom"]');
  if (!$joinClassroom.length) return;

  // join limit modal
  const joinLimitModal = '[data-selector="classroom-join-limit-modal"]';
  const $joinLimitModal = $(joinLimitModal);
  const joinLimitModalOk = '[data-selector="classroom-join-limit-modal-ok"]';
  const joinLimitModalClose = '[data-selector="classroom-join-limit-modal-close"]';
  const joinLimitModalOverlay = '[data-selector="classroom-join-limit-modal-overlay"]';

  const joinClassroomBtn = '[data-selector="join-classroom-btn"]';
  const $joinClassroomBtn = $(joinClassroomBtn);
  const nameInput = '[data-selector="student-name-input"]';
  const $nameInput = $(nameInput);
  const codeInput = '[data-selector="join-code-input"]';
  const $codeError = $('[data-selector="join-code-error"]');

  const activeClass = '_active';
  const userAvatar = '[data-selector="user-avatar"]';
  const $userAvatar = $(userAvatar);
  const $userName = $('[data-selector="user-name"]');
  const $editUser = $('[data-selector="edit-user"]');
  const changeNameLink = '[data-selector="change-name-link"]';
  const hideClassName = '_hide';

  // if student is trying to join "closed" classroom then we show error message
  if (getUrlParam(GET_PARAMS.ERROR) === 'true') $codeError.show();

  if ($(codeInput).val().length) $(codeInput).prop('disabled', false);

  const postAnalyticsEvent = (
    eventType = '',
    page = '',
    element = '',
    value = 'click'
  ) => {
    posthog.capture(eventType, {
      usage_step: page,
      usage_data: {
        element: element,
        value: value
      }
    });
  };

  const checkCode = (callback = () => {}) => {
    const payload = {
      code: $(codeInput).val(),
      name: $(nameInput).val()
    };

    $.ajax({
      url: ENDPOINT.CLASSROOM_CODE_CHECK,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(payload),
      success: (response) => {
        callback(response.id);
      },
      error: (error) => {
        toggleButtonLoading($joinClassroomBtn, false);
        postAnalyticsEvent('Join incorrect', 'Sign up', 'join by code button');

        // show limit modal
        const responseError =  JSON.parse(error.responseText);
        if (responseError && responseError.error_code === ERROR_CODES.STUDENT_LIMIT_EXCEEDED) {
          postAnalyticsEvent('Limit popup', 'Sign up');
          return $joinLimitModal.show();
        }

        $codeError.show();
      }
    });
  };

  const makeAuth = (callback = () => {}, method = 'POST') => {
    const payload = {};

    if ($(nameInput).val().length) payload.first_name = $(nameInput).val();

    const $selectedAvatarImg = $(`${userAvatar}.${activeClass}`);
    if ($selectedAvatarImg.length) payload.data = {avatarId: $selectedAvatarImg.attr('id')};

    if (method === 'POST') {
      payload.email = null;
      payload.need_to_login = true;
      payload.account_type = ACCOUNT_TYPES.PARENT;
      payload.source = 'classroom';
      payload.medium = 'classroom';
      payload.campaign = 'classroom';
      payload.placement = 'join code page';
    }

    $.ajax({
      url: ENDPOINT.USER_PROFILE,
      contentType: 'application/json',
      type: method,
      data: JSON.stringify(payload)
    }).then(() => {
      window.boot.IS_LOGGED_IN = true;
      callback();
    }, (error) => {
      /* eslint-disable */
        console.log('error', error);
      /* eslint-enable */
    });
  };

  const joinClassroom = (classroomId) => {
    $.ajax({
      url: ENDPOINT.CLASSROOM_JOIN(classroomId),
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({name: $(nameInput).val()}),
      success: (response) => {
        postAnalyticsEvent('Join', 'Sign up', 'join by code button');

        setTimeout(() => {
          window.location = response.student_link;
        }, 2000); // delay for redirect to post analytics event correctly
      },
      error: () => {
        postAnalyticsEvent('Join incorrect', 'Sign up', 'join by code button');
        toggleButtonLoading($joinClassroomBtn, false);
      }
    });
  };

  $(document)
    .on('click', joinLimitModalOverlay, (e) => {
      e.stopPropagation();
      $joinLimitModal.hide();
    })
    .on('click', joinLimitModalOk, (e) => {
      e.stopPropagation();
      $joinLimitModal.hide();
    })
    .on('click', joinLimitModalClose, (e) => {
      e.stopPropagation();
      $joinLimitModal.hide();
    })
    .on('click', userAvatar, (e) => {
      $userAvatar.removeClass(activeClass);
      $(e.target).addClass(activeClass);
    })
    .on('click', changeNameLink, () => {
      $editUser.removeClass(hideClassName);
      $userName.addClass(hideClassName);
    })
    .on('focus', codeInput, () => {
      $codeError.hide();
    })
    .on('input', codeInput, (e) => {
      $joinClassroomBtn.prop('disabled', !e.target.value.length);
    })
    .on('paste', codeInput, (e) => {
      $joinClassroomBtn.prop('disabled', !e.target.value.length);
    })
    .on('click', joinClassroomBtn, () => {
      toggleButtonLoading($joinClassroomBtn, true);

      if (window.boot.IS_LOGGED_IN) {
        if ($nameInput.val() || $(`${userAvatar}.${activeClass}`).length) {
          makeAuth(() => checkCode(id => joinClassroom(id)), 'PATCH');
        } else {
          checkCode(id => joinClassroom(id));
        }
      } else {
        makeAuth(() => checkCode(id => joinClassroom(id)), 'POST');
      }
    });

  $(document)
    .keydown((event) => { // Enter key press
      if (event.which === 13) $joinClassroomBtn.trigger('click');
    });
});
