/* eslint-disable */
// TODO delete unused vars and methods, TEST after refactor!!!

import {
  braintreeDropinConfig,
  capitalize,
  initWorksheetDownload,
  getError,
  isFormValid,
  isThreeDSecureCanceled,
  storage,
  threeDSecureConfig,
  toggleButtonLoading,
  toggleMarkWorksheetAsDone,
  toggleAddWorksheetToFavorite,
  handlePdfDownload,
  dispatchCustomEvent,
  addActivityToClassroom,
  redirectToCompleteOnlineInTGC,
  handleCompleteOnline,
  downloadWorksheetHandler,
} from '../utils';
import {
  ACCOUNT_TYPES,
  CURRENCY,
  CUSTOM_MESSAGE,
  DROPIN_SCRIPT_URL,
  ENDPOINT,
  LOCAL_STORAGE_KEYS,
  PLACEMENTS,
  PURCHASE_STEP_TRIGGERED_ATTRIBUTE,
  SUBSCRIPTION_PERIOD_MAPPING,
  SUBSCRIPTION_PERIODS,
  WORKSHEET_DOWNLOAD_DELAY,
  MODALS,
  WORKSHEET_ACTIONS,
  WEBSITE_CUSTOM_EVENTS,
  URLS,
  LANGUAGE_CODES,
  UTM,
  WORKSHEET_ACTION_TYPES,
} from '../config';
import {
  doCheckIsCouponDistributorCode,
  getCurrencyPrice,
  getSelectedOfferDetails,
  getTotalPricesInCents,
  handleDropinToken,
  setCheckoutBtnTitle,
  toggleApplyCouponBtn,
  toggleCouponBlock
} from '../payment/subscription.utils';

$(document).ready(function () {
  const $worksheetListPartial = $('[data-selector="worksheet-list-partial"]');
  if (!$worksheetListPartial.length) return;

  const basePath = '/free-preschool-kindergarten-worksheets/';
  let printableWorksheetsPath = '/printable-worksheets/';

  const $websiteQuestionFavouritesModal = $(MODALS.WEBSITE_QUESTION_FAVOURITES_MODAL);
  const $websiteQuestionDoneModal = $(MODALS.WEBSITE_QUESTION_DONE_MODAL);

  const activeFilterClassname = '_active';

  let filterSubjects = {subjects: []};

  const searchFormSelector = '#search-form';
  const searchQuerySelector = '#search-form .search-query';
  const checkedGradeInputSelector = '#grade-items input[name=grade]:checked';
  const difficultyFilterItem = '[data-selector="filter-item"]';
  const checkedDifficultyInputSelector = '#difficulty-items input[name=difficulty]:checked';
  const difficultyInput = 'input[name=difficulty]';
  const checkedDifficultyFilterRemove =
    '#difficulty-items .learning-resources__filter-list-item-label > .icon-cross';
  const difficultyInputsSelector = '#difficulty-items input[type=radio]';
  const difficultyLinkSelector = '#difficulty-items a';
  const parentSubjectsSelector = '#subject-items > .subject';
  const spinnerSelector = '[data-selector="spinner"]';
  const $seoDescriptionContainer = $('[data-selector="seo-description-container"]');
  const seoDescription = '[data-selector="seo-description"]';
  const $seoDescriptionBlock = $(seoDescription);
  const pageTitleSelector = '#page-title';
  const pageTextSelector = '#page-text';
  const breadcrumbsSelector = '#breadcrumbs';
  const worksheetsHolderSelector = '#worksheets-holder';
  const seoBottomTextSelector = '[data-selector="bottom-description"]';
  const filterSelector = '#filter';
  const mobileResultsCounterSelector = '[data-selector="count-results-mob"]';
  const removeFiltersSelector = '#remove-filters, #remove-filters-mob';
  const filterInputsSelector = '#grade-items input[type=radio]';
  const gradeLabelsSelector = '#grade-items input[type=radio]:checked + label';
  const subSubjectsSelector = '.learning-resources__filter-list-item-sub-subject-items';
  const gradeLinksSelector = '#grade-items a';
  const filterItemSelector = '.learning-resources__filter-list-item';
  const subjectLinksSelector = '#subject-items .learning-resources__filter-list-item-label-name';
  const subjectLinksSearchResultsSelector = '#subject-items-search-results .learning-resources__filter-list-item-label-name';
  const subjectSelectorRemove =
    '#subject-items .learning-resources__filter-list-item-label > .icon-cross';
  const filterTagRemove = '#filter-tags > .filter-tags__item > .icon-cross';
  const subjectSelector = '.subject';
  const selectedSubjectSelector = '#subject-items .subject.selected';
  const openClassName = 'open';
  const selectedClassName = 'selected';
  const activeClassName = 'active';
  const filterBgSelector = '#filter-inner-close';
  const filtersLabelSelector = '#filters-mob-open';
  const metaDescriptionSelector = 'meta[name="description"]';
  const linkCanonicalSelector = 'link[rel="canonical"]';
  const sortSelector = '#sort-by';
  const filterTagsSelector = '#filter-tags';
  const filterTagItemSelector = '.filter-tags__item';

  const learningSkillsHolder = '[data-selector="learning-skills-holder"]';
  const learningSkills = '[data-selector="learning-skills"]';

  const keyFilterSelector = '[data-selector="answer-key-filter"]';
  const onlineFilterSelector = '[data-selector="online-filter"]';
  let ignoreSorting = $(onlineFilterSelector).hasClass(activeFilterClassname);
  const favoriteFilterSelector = '[data-selector="favorite-filter"]';
  const hideDoneFilterSelector = '[data-selector="hide-done"]';
  const markDoneItemIcon = '[data-selector="learning-resources-mark-done"]';
  const markFavoriteItemIcon = '[data-selector="learning-resources-mark-favorite"]';

  const goToWebCheckItemIconSelector = '[data-selector="learning-resources-web-check"]';
  const learningResourcesItemSelector = '[data-selector="learning-resources-item"]';
  const learningResourcesItemLink = '[data-selector="learning-resources-item-link"]';
  const downloadItemIconSelector = '[data-selector="learning-resources-download"]';
  const learningResourcesItemTitle = '[data-selector="learning-resources-item-title"]';

  const filterDescriptionSelector = '[data-selector="filter-description"]';
  const worksheetsRelatedBlockSelector = '[data-selector="worksheets-related"]';

  const lessonPreviewWidgetSelector = '[data-selector="lesson-preview-widget"]';

  const $hideDoneMain = $('[data-selector="is-hide-done-active"]');

  // view toggle
  const cardsViewMode = 'cards';
  const listViewMode = 'list';
  const cardsViewBtn = '[data-view-type="cards"]';
  const listViewBtn = '[data-view-type="list"]';
  const viewToggle = '[data-selector="view-toggle"]';
  const viewToggleIcon = '[data-selector="view-toggle-icon"]';
  const listView = '_list-view';
  const activeClass = '_active';
  const worksheetListView = 'worksheetListView';
  const isPageFirstLoad = 'isWorksheetListPageFirstLoad';

  const IS_LOGGED_IN = window.isLoggedIn;
  const $sortBySelect = $(sortSelector);

  // subject-activity collapse filters
  const animationSpeed = 400;
  const openedClass = '_opened';
  const collapseItemBtn = '[data-selector="collapse-item-btn"]';
  const collapseItem = '[data-selector="collapse-item"]';
  const collapseItemContent = '[data-selector="collapse-item-content"]';
  const collapseContainer = '[data-selector="collapse-container"]';

  // activity filter
  const parentActivitySelector = '#activity-items > [data-selector="activity-filter-item"]';
  const activityFilterItem = '[data-selector="activity-filter-item"]';
  const activityFilterLink = '[data-selector="activity-filter-link"]';
  const removeActivityFilterBtn = '[data-selector="remove-activity-filter-btn"]';
  const selectedActivitySelector = '#activity-items .subject.selected';
  let $selectedActivity = $(selectedActivitySelector);
  $selectedActivity = $selectedActivity.length ? $selectedActivity : null;

  // standards filter
  const standardFilterItem = '[data-selector="standard-filter-item"]';
  const standardFilterLink = '[data-selector="standard-filter-link"]';
  const removeStandardFilterBtn = '[data-selector="remove-standard-filter-btn"]';
  const selectedStandardSelector = '#standard-items .subject.selected';
  let $selectedStandard = $(selectedStandardSelector);
  $selectedStandard = $selectedStandard.length ? $selectedStandard : null;

  // subjects filter
  const subjectFilterItem = '[data-selector="subject-filter-item"]';
  let $selectedSubject = $(selectedSubjectSelector);
  $selectedSubject = $selectedSubject.length ? $selectedSubject : null;

  const sortedClassName = '_sorted';
  const hiddenClassName = '_hidden';
  const scrollableClassName = '_scrollable';

  // filter search
  let SEARCH_REQUEST = {
    type: '',
    text: ''
  };

  const FILTER_TYPE = {
    STANDARDS: 'standards'
  };

  // classroom assign
  const assignToClassroom = '[data-selector="learning-resources-assign-to-classroom"]';

  let filterSearchDebounce;
  const filterSearchDebounceDelay = 300;
  const minSymbolToSearchLength = 3;
  const searchFilterContainer = '[data-selector="search-filter-container"]';
  const searchFilter = '[data-selector="search-filter"]';
  const emptyFilterSearchDataAttr = 'data-selector="empty-filter-search-result"';
  const emptyFilterSearchText = `[${emptyFilterSearchDataAttr}]`;
  const filterSearchClearBtn = '[data-selector="filter-search-clear-btn"]';
  const filterSearchBtn = '[data-selector="filter-search-btn"]';
  const filterItemsContainer = '#subject-items[data-selector="filter-list-container"]';
  const filterItemsSearchResultsContainer = '#subject-items-search-results[data-selector="filter-list-container"]';
  const subjectSearchResultsRemove = '#subject-items-search-results [data-selector="remove-subject-filter-btn"]';
  const filterSearchInput = '[data-selector="filter-search-input"]';

  let searchFilterItemsArray = {
    'standard': $(standardFilterItem),
    'activity': $(activityFilterItem),
    'subject': $(subjectFilterItem)
  };

  // monetization popup
  const monetizationPopupsGlobalSelector = '[data-selector="monetization-popup"]';
  const downloadText = '[data-selector="monetization-popup-download-text"]';
  const downloadLink = '[data-selector="monetization-popup-download-link"]';
  const monetizationPopupContentSelector = '[data-selector="monetization-popup-content"]';
  const subscriptionStepClass = '_subscription-step';
  const downloadHrefAttr = 'data-download-href';
  const idAttr = 'data-id';
  const slugAttr = 'data-slug';

  // plan attributes
  const planAttr = {
    isTrialRadioEnabled: 'data-is-trial-radio-enabled',
    slug: 'data-slug',
    trialDuration: 'data-trial-duration',
    offerPeriod: 'data-offer-period',
    price: 'data-price',
    backUrl: 'data-offer-back-url',
    offerPeriodDisplay: 'data-offer-period-display',
    submitText: 'data-offer-submit',
    isCouponEnabled: 'data-is-coupon-enabled'
  };
  const section = {
    first: 0,
    second: 1
  };

  // subscription details elements
  const $planPeriod = $('[data-selector="monetization-popup-plan-period-value"]');
  const $planFullPrice = $('[data-selector="monetization-popup-plan-full-price-value"]');
  const $planTrialPeriod = $('[data-selector="monetization-popup-plan-trial-period-value"]');
  const $planDiscountRow = $('[data-selector="monetization-popup-plan-discount-row"]');
  const $planTrialRow = $('[data-selector="monetization-popup-plan-trial-row"]');
  const $planDiscount = $('[data-selector="monetization-popup-plan-discount-value"]');
  const $planTotalPrice = $('[data-selector="monetization-popup-plan-total-price-value"]');
  const $planRenewCondition = $('[data-selector="monetization-popup-plan-renew-condition"]');

  const braintreeDropinSelector = '[data-selector="dropin-container"]';
  const shippingCountriesSelect = '[data-selector="shipping-countries-select"]';
  const shippingFormItemSelect = '[data-selector="shipping-addon-form-item"]';
  const hiddenInputSelect = '[data-selector="monetization-popup-special-input"]';

  const $subscriptionError = $('[data-selector="monetization-popup-subscription-error"]');
  const $submitPaymentBtn = $('[data-selector="monetization-popup-submit-payment-btn"]');

  // age filter
  const ageRangeFilter = '[data-selector="age-range-filter"]';
  const ageFilterItem = '[data-selector="age-filter-list-item"]';
  const checkedAgeInputSelector = '#age-items input[name=age]:checked';
  const removeAgeFilterBtn = '[data-selector="remove-age-filter-btn"]';
  const ageRangeFilterButton = '[data-selector="age-range-filter-btn"]';
  const ageRangeRemoveFilterButton = '[data-selector="age-range-remove-filter-btn"]';
  const removeAgeTagFilter = '[data-selector="remove-age-tag-filter"]';
  const ageCollapseItem = '[data-selector="collapse-item"][data-type="age"]';
  let ageRangeFilterIsOpen = false;
  let ageCollapseIsOpen = false;

  const activeSkillTag = '#filter-tags [data-type="skill"]';
  let activeSkillSlug = $(activeSkillTag).data('slug');

  const changeAgeRangeFilterState = (isOpen) => {
    if(isOpen) {
      $(ageRangeFilter).addClass('open');
      ageRangeFilterIsOpen = true;

      return;
    }

    $(ageRangeFilter).removeClass('open');
    ageRangeFilterIsOpen = false;
  };

  const checkAgeRangeFilterShouldBeOpen = () => {
    const isRangeAge = checkIsActiveRangeAge();

    if ($(checkedAgeInputSelector).val()) {
      $(ageCollapseItem).addClass('_opened');
      ageCollapseIsOpen = true;
    }

    if (isRangeAge) changeAgeRangeFilterState(true);
  };

  const checkIsActiveRangeAge = () => {
    const age = $(checkedAgeInputSelector).val() || '';

    return !!age.match(/age-.-./);
  };

  let playerCounter = 0;

  $sortBySelect.select2({
    minimumResultsForSearch: -1
  });
  const $filterTags = $(filterTagsSelector);
  let searchString = $(searchQuerySelector).val();

  const openCollapsedFiltersIfSearch = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.get('search');
    if (search) openCollapsedFilters();
  };

  const openCollapsedFilters = () => {
    setTimeout(() => {
      $(collapseItemBtn).each((i, elem) => {
        if ($(elem).attr('data-type') === 'subject') $(elem).trigger('click');
      });
    }, 10);
  };

  if (!localStorage.getItem(isPageFirstLoad)) {
    openCollapsedFilters();
    localStorage.setItem(isPageFirstLoad, 'true');
  } else {
    openCollapsedFiltersIfSearch();
  }

  const setWorksheetsView = (viewType, isSave = true) => {
    const $toggleIcon = $(viewToggle).find(viewToggleIcon);
    $toggleIcon.removeClass(activeClass);
    const $adsense = $('[data-selector="worksheet-list-adsense"]');

    switch(viewType) {
      case cardsViewMode:
        // set cards view
        $(cardsViewBtn).addClass(activeClass);
        $worksheetListPartial.removeClass(listView);
        if (isSave) localStorage.setItem(worksheetListView, cardsViewMode);
        $adsense.addClass(hiddenClassName);
        break;

      case listViewMode:
        // set list view
        $(listViewBtn).addClass(activeClass);
        $worksheetListPartial.addClass(listView);
        if (isSave) localStorage.setItem(worksheetListView, listViewMode);
        $adsense.removeClass(hiddenClassName);
        break;
    }
  };

  const handleUrlToChangeWorksheetsView = () => {
    if (window.location.pathname.split('/').indexOf('coloring-pages') > -1) {
      setWorksheetsView(cardsViewMode, false);
    } else {
      let mode = localStorage.getItem(worksheetListView);
      if (!mode) mode = listViewMode;
      setWorksheetsView(mode, true);
    }
  };

  handleUrlToChangeWorksheetsView();

  document
    .addEventListener(WEBSITE_CUSTOM_EVENTS.DOWNLOAD_WORKSHEET, (e) => {
      const worksheetId = e.detail.worksheetId;
      const pdfLink = getWorksheetPdfLinkById(worksheetId);
      initWorksheetDownload(pdfLink);
    }, false);

  const getUserSources = (key) => {
    if (!key) return '';
    if (!window.sourcesOfUser) return '';
    if (!window.sourcesOfUser.hasOwnProperty(key)) return '';

    const result = window.sourcesOfUser[key];
    if (result === 'None') return null;

    return result;
  };

  const $worksheetListSlider = $('[data-selector="worksheets-list-slider"]');
  const $worksheetListSliderWrapper = $('[data-selector="worksheets-list-slider-wrapper"]');

  $worksheetListSlider.on('init', () => {
    $worksheetListSliderWrapper.show();
    ga('send', 'event', 'WH_carrousel', 'Show', 'Load');
  });

  const worksheetsListCarouselSettings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerPadding: '2%',
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 4
      }
    },{
      breakpoint: 991,
      settings: {
        slidesToShow: 3
      }
    },{
      breakpoint: 767,
      settings: {
        slidesToShow: 4
      }
    },{
      breakpoint: 670,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 580,
      settings: {
        centerMode: true,
        slidesToShow: 2
      }
    },{
      breakpoint: 400,
      settings: {
        slidesToShow: 1
      }
    }]
  };

  $worksheetListSlider.not('.slick-initialized').slick(worksheetsListCarouselSettings);

  const buildUrl = function (page, appliedFilterType= null) {
    let url = '';
    let standardLink = null;
    let activitySlug = null;
    let subSubjectSlug = null;
    let subjectSlug = null;
    let worksheetsPath = printableWorksheetsPath;

    // online/interactive filter
    const isOnlineFilterOn = $(onlineFilterSelector).hasClass(activeFilterClassname);

    // with answer keys
    const isWithAnswerKeyFilterOn = $(keyFilterSelector).hasClass(activeFilterClassname);

    // standard
    if ($selectedStandard) {
      standardLink = $selectedStandard.find(standardFilterLink).attr('href');

      if (appliedFilterType === FILTER_TYPE.STANDARDS) {
        url = standardLink;
      } else if (isWithAnswerKeyFilterOn) {
        url = standardLink.replace('/printable-worksheets/', '/printable-worksheets/answer-key/');
        url = url.replace('/online/', '/');
      } else if (isOnlineFilterOn) {
        url = standardLink.replace('/printable-worksheets/', '/printable-worksheets/online/');
        url = url.replace('/answer-key/', '/');
      } else {
        url = standardLink.replace('answer-key/', '');
      }

      url = url.replace('/answer-key/answer-key/', '/answer-key/');
      url = url.replace('/online/online/', '/online/');

    } else {
      // activity
      let $activityItem = $selectedActivity ? $selectedActivity.closest(parentActivitySelector) : null;

      let subActivitySlug = null;
      if ($selectedActivity && $activityItem.data('id') !== $selectedActivity.data('id')) {
        subActivitySlug = $selectedActivity.data('slug');
      }

      if ($activityItem) activitySlug = $activityItem.data('slug');

      // subject
      let $subjectItem = $selectedSubject ? $selectedSubject.closest(parentSubjectsSelector) : null;

      if ($selectedSubject && $subjectItem.data('id') !== $selectedSubject.data('id')) {
        subSubjectSlug = $selectedSubject.data('slug');
      }

      if ($subjectItem) subjectSlug = $subjectItem.data('slug');

      // grade
      const gradeSlug = $(checkedGradeInputSelector).val();

      // difficulty
      const difficultySlug = $(checkedDifficultyInputSelector).val();

      // age
      const ageSlug = $(checkedAgeInputSelector).val();

      // construct url
      url = difficultySlug
         || isOnlineFilterOn
         || isWithAnswerKeyFilterOn
         || gradeSlug
         || ageSlug
         || subjectSlug
         || subSubjectSlug
         || activitySlug
         || activeSkillSlug
         || subActivitySlug ? worksheetsPath : basePath;

      if (isWithAnswerKeyFilterOn) url += 'answer-key/';
      if (isOnlineFilterOn) url += 'online/';
      if ($selectedActivity) url += 'activities/';

      if (difficultySlug) url += difficultySlug + '/';
      if (gradeSlug) url += gradeSlug + '/';
      if (ageSlug) url += ageSlug + '/';
      if (subjectSlug) url += subjectSlug + '/';
      if (subSubjectSlug) url += subSubjectSlug + '/';
      if (activitySlug) url += activitySlug + '/';
      if (subActivitySlug) url += subActivitySlug + '/';
      if (activeSkillSlug) url += `learning-skills/${activeSkillSlug}/`;
    }

    // sorting
    let sortByOption = $sortBySelect.val();
    const favoriteValue = $(favoriteFilterSelector).hasClass(activeFilterClassname);
    const hideDoneValue = $(hideDoneFilterSelector).find('input').is(':checked');

    /* we need to specify an explicit sort type if "Online filter" is active
    cause server sets sorting to "interactive" if no sorting type has been added to url by params */

    /* if "Interactive" filter has been enabled - server sets sorting to "interactive"
    *  we need to reset sorting if "Interactive" filter has been disabled */
    if (ignoreSorting
     || sortByOption === 'popularity' && !isOnlineFilterOn
     || sortByOption === 'popularity' && isWithAnswerKeyFilterOn ) sortByOption = '';

    let query = [];
    if (searchString) query.push('search=' + searchString);
    if (page && page !== 1) query.push('page=' + page);
    if (sortByOption) query.push('ordering=' + sortByOption);
    if (favoriteValue) query.push('favorite=' + favoriteValue);
    if (hideDoneValue) query.push('hide_done=' + hideDoneValue);
    query = query.join('&');

    if (query) url += '?' + query;

    /* Action for adding the page number to the title of page */
    page = page ? page : 1;
    const titleString = document.title;
    if (titleString.search(/ - Page (\d+)/) > 0)
      document.title = page !== 1 ? titleString.replace(/ - Page (\d+)/, ' - Page '.concat(page))
        : titleString.replace(/ - Page (\d+)/, '');
    else if (page !== 1) document.title += ' - Page '.concat(page);

    // add lang prefix
    if (!standardLink && window.LANGUAGE_CODE === LANGUAGE_CODES.SPANISH) {
      url = `/${LANGUAGE_CODES.SPANISH}${url}`;
    }

    return url;
  };

  const defaultOptions = {
    pushState: true
  };

  const $filter = $(filterSelector);

  let ajaxRequest = null;

  const updateWorksheets = function (url, options) {
    $(spinnerSelector).show();

    options = $.extend({}, defaultOptions, options);
    ajaxRequest = $.ajax({
      type: 'GET',
      url: url,
      contentType: 'text/partial_html',
      data: {
        // The `partial` parameter helps to build a unique URL for a partial page.
        // The server will use the URL to fetch a page from the cache.
        partial: true,
      },
      cache: true,
      beforeSend: function () {
        if (ajaxRequest) {
          ajaxRequest.abort();
        }
      },
      success: function (data) {
        const $newPage = $('<div>').html(data);
        const $title = $(pageTitleSelector);
        const $text = $(pageTextSelector);
        const $breadcrumbs = $(breadcrumbsSelector);
        const $worksheets = $(worksheetsHolderSelector);
        const $learningSkillsHolder = $(learningSkillsHolder);
        const $seoText = $(seoBottomTextSelector);
        const $lessonPreviewWidget = $(lessonPreviewWidgetSelector);

        const newSeoBottomText = $newPage.find(seoBottomTextSelector).html();
        const newTitle = $newPage.find(pageTitleSelector).html();
        const newText = $newPage.find(pageTextSelector).html();
        const newBreadcrumbs = $newPage.find(breadcrumbsSelector).html();
        const newWorksheets = $newPage.find(worksheetsHolderSelector).html();
        const newFilter = $newPage.find(filterSelector).html();
        const $newMobileFilter = $newPage.find(mobileResultsCounterSelector);
        const newMetaDescription = $newPage
          .find(seoBottomTextSelector)
          .attr('data-meta-description');
        const position = $worksheets.offset().top - $(window).scrollTop();
        const dataTitle = $newPage.find(pageTitleSelector).attr('data-title');
        const dataCanonical = $newPage.find(pageTitleSelector).attr('data-canonical');
        const newFilterTags = $newPage.find(filterTagsSelector).html();

        const newLearningSkills = $newPage.find(learningSkills).html();
        const sortOptionsRenewedSelected = $newPage.find(sortSelector).html();
        const worksheetsRelatedBlock = $newPage.find(worksheetsRelatedBlockSelector).html();

        const newLessonPreviewWidget = $newPage.find(lessonPreviewWidgetSelector).html();

        const seoDescriptionBlock = $newPage.find(seoDescription).html().trim();
        const contentClass = '_has-content';

        if (seoDescriptionBlock) {
          $seoDescriptionBlock.html(seoDescriptionBlock);
          $seoDescriptionContainer.addClass(contentClass);
        } else {
          $seoDescriptionBlock.html('');
          $seoDescriptionContainer.removeClass(contentClass);
        }

        $seoText.html(newSeoBottomText);
        $learningSkillsHolder.html(newLearningSkills);
        $title.html(newTitle);
        $title.attr('data-title', dataTitle);
        $text.html(newText);
        $breadcrumbs.html(newBreadcrumbs);
        $worksheets.html(newWorksheets);
        $filter.html(newFilter);
        $filterTags.html(newFilterTags);
        $sortBySelect.html(sortOptionsRenewedSelected);
        $lessonPreviewWidget.html(newLessonPreviewWidget || '');

        const filterDescription = $newPage.find(filterDescriptionSelector).html();
        $(filterDescriptionSelector).html(filterDescription);

        $(mobileResultsCounterSelector).text($newMobileFilter.text());
        $(worksheetsRelatedBlockSelector).html(worksheetsRelatedBlock);
        if (typeof window.initRelatedWorksheetsSlider === 'function') {
          window.initRelatedWorksheetsSlider();
        }
        if (typeof window.initRelatedArticlesSlickSlider === 'function') {
          window.initRelatedArticlesSlickSlider();
        }
        searchString = $(searchQuerySelector).val();
        document.title = dataTitle;
        $(metaDescriptionSelector).attr('content', newMetaDescription);
        $(linkCanonicalSelector).attr('href', dataCanonical);
        $(window).scrollTop($worksheets.offset().top - position);
        ajaxRequest = null;

        if (SEARCH_REQUEST.type) {
          const $filteredItems = $(`[data-selector="${SEARCH_REQUEST.type}-filter-item"]`);
          searchFilterItemsArray[SEARCH_REQUEST.type] = $filteredItems;

          // show search mode if search text existed
          if (SEARCH_REQUEST.text && SEARCH_REQUEST.text.length) {
            const $searchFilterContainer = $filteredItems.closest(searchFilterContainer);
            toggleFilterSearchIcons(SEARCH_REQUEST.text, $searchFilterContainer);
            $searchFilterContainer.find(filterSearchInput).val(SEARCH_REQUEST.text);
            updateUiWithAppliedSearch(SEARCH_REQUEST.text, $searchFilterContainer);
          }
        }

        handleUrlToChangeWorksheetsView();

        openCollapsedFiltersIfSearch();

        document.dispatchEvent(new CustomEvent(WEBSITE_CUSTOM_EVENTS.RELOAD_HTMX_ADS_BANNERS));

        $(spinnerSelector).hide();

        if(ageRangeFilterIsOpen) $(ageRangeFilter).addClass('open');
        if(ageCollapseIsOpen) $('[data-selector="collapse-item"][data-type="age"]').addClass('_opened');
        activeSkillSlug = $(activeSkillTag).data('slug');
      },
      error: function () {
        ajaxRequest = null;
        $(spinnerSelector).hide();
      }
    });
    if (options.pushState) {
      if (window.history && typeof window.history.pushState === 'function') {
        window.history.pushState(null, null, url);
      }
    }
  };

  function clearCurrentSearchValue() {
    $(searchQuerySelector).val('');
    searchString = '';
  }

  function showFilter() {
    $(filterSelector).addClass(activeClassName);
    $(filterBgSelector).addClass(activeClassName);
  }

  function hideFilter() {
    $(filterSelector).removeClass(activeClassName);
    $(filterBgSelector).removeClass(activeClassName);
  }

  function toggleFilter() {
    if ($(filterSelector).hasClass(activeClassName)) {
      hideFilter();
    } else {
      showFilter();
    }
  }

  function handleRemoveSubjectFilter($subjectItem) {
    if (!$subjectItem) return;
    const subjectId = $subjectItem.data('id');

    const $parentSubject = $subjectItem.closest(`${subjectFilterItem}:not(#${subjectId})`);
    if ($parentSubject.length) {
      $selectedSubject = $parentSubject;
      $parentSubject.parents(subjectSelector).addClass(openClassName);
      $parentSubject.addClass(`${openClassName} ${selectedClassName}`);
      return;
    }

    $selectedSubject = null;
  }

  function handleRemoveActivityFilter($activityItem) {
    if (!$activityItem) return;
    const activityId = $activityItem.data('id');

    const $parentActivity = $activityItem.closest(`${activityFilterItem}:not(#${activityId})`);
    if ($parentActivity.length) {
      $selectedActivity = $parentActivity;
      $parentActivity.parents(activityFilterItem).addClass(openClassName);
      $parentActivity.addClass(`${openClassName} ${selectedClassName}`);
      return;
    }

    $selectedActivity = null;
  }

  function handleRemoveStandardFilter($standardItem) {
    if (!$standardItem) return;
    const standardId = $standardItem.data('id');

    const $parentStandard = $standardItem.closest(`${standardFilterItem}:not(#${standardId})`);

    if ($parentStandard.length) {
      $selectedStandard = $parentStandard;
      $parentStandard.parents(standardFilterItem).addClass(openClassName);
      $parentStandard.addClass(`${openClassName} ${selectedClassName}`);
      return;
    }

    $selectedStandard = null;
  }

  function handleFavoriteFilterClick() {
    const $filter = $(favoriteFilterSelector);
    $filter.toggleClass(activeFilterClassname);
    const url = buildUrl();
    updateWorksheets(url);
  }

  function handleOnlineFilterClick() {
    const $filter = $(onlineFilterSelector);

    $(keyFilterSelector).removeClass(activeFilterClassname);
    $filter.toggleClass(activeFilterClassname);

    if ($(onlineFilterSelector).hasClass(activeFilterClassname)) ignoreSorting = true;

    const url = buildUrl();
    updateWorksheets(url);
  }

  function handleAnswerKeyFilterClick() {
    const $filter = $(keyFilterSelector);
    $filter.toggleClass(activeFilterClassname);

    if ($(onlineFilterSelector).hasClass(activeFilterClassname)) ignoreSorting = true;

    $(onlineFilterSelector).removeClass(activeFilterClassname);
    const url = buildUrl();
    updateWorksheets(url);
  }

  function handleAccomplishedFilterClick() {
    const $filterInput = $(hideDoneFilterSelector).find('input');
    const filterValue = $filterInput.is(':checked');
    if (filterValue) {
      $filterInput.prop('checked', false);
    } else {
      $filterInput.prop('checked', true);
    }
    const url = buildUrl();
    updateWorksheets(url);
  }

  const getWorksheetPdfLinkById = (worksheetId) => {
    const $worksheetItem = $(`${learningResourcesItemSelector}[data-id="${worksheetId}"]`);
    const $downloadIcon = $worksheetItem.find(downloadItemIconSelector);
    return $downloadIcon.attr(downloadHrefAttr);
  };

  function handleGoToWebCheck(e, queryParams) {
    e.preventDefault();

    callGaEvents(
      () => {ga('send', 'event', 'WH_Category_list', 'online', 'Click');},
      () => {ga('send', 'event', 'WH_Category_tile', 'online', 'Click');}
    );

    const $activityItem = getActivityItem($(e.currentTarget));
    const worksheetId = getWorksheetId($activityItem);
    const worksheetSlug = getWorksheetSlug($activityItem);

    storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ID, worksheetId);
    storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_SLUG, worksheetSlug);
    storage.set(LOCAL_STORAGE_KEYS.PLACEMENT, PLACEMENTS.WORKSHEET_LIST_PAGE);
    storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION, WORKSHEET_ACTIONS.GO_TO_WORKSHEET_CHECK_IN_TGC);

    redirectToCompleteOnlineInTGC(worksheetSlug, queryParams);

    // if (IS_LOGGED_IN) {
    //   handleCompleteOnline(() => {
    //     const worksheetId = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ID);
    //     const worksheetSlug = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_SLUG);
    //
    //     toggleMarkWorksheetAsDone(worksheetId, true, () => {
    //       toggleUiViewOfDoneIcon(worksheetId, true);
    //     });
    //
    //     redirectToCompleteOnlineInTGC(worksheetSlug);
    //   });
    // }

    // const $websiteAccTypeModal = $(MODALS.ACCOUNT_TYPE_MODAL);
    // $(MODALS.WORKSHEET_IMAGE_POPUP).modal('hide');
    // $websiteAccTypeModal.modal('show');
  }

  const toggleUiViewOfDoneIcon = (worksheetId, isPostMethod) => {
    const $worksheetItem = $(`${learningResourcesItemSelector}[data-id="${worksheetId}"]`);
    const $doneIcon = $worksheetItem.find(markDoneItemIcon);

    if (isPostMethod) {
      $doneIcon.addClass('_active');
      $doneIcon.prop('checked', true);
    } else {
      $doneIcon.prop('checked', false);
      $doneIcon.removeClass('_active');
    }
  };

  const toggleUiViewOfFavoriteIcon = (worksheetId, isPostMethod) => {
    const $worksheetItem = $(`${learningResourcesItemSelector}[data-id="${worksheetId}"]`);
    const $favoriteIcon = $worksheetItem.find(markFavoriteItemIcon);

    if (isPostMethod) {
      $favoriteIcon.addClass('_active');
      $favoriteIcon.prop('checked', true);
    } else {
      $favoriteIcon.removeClass('_active');
      $favoriteIcon.prop('checked', false);
    }
  };

  const handleMarkFavorite = (e) => {
    e.preventDefault();

    callGaEvents(
        () => {ga('send', 'event', 'WH_Category_list', 'favorite', 'Click')},
        () => {ga('send', 'event', 'WH_Category_tile', 'favorite', 'Click');}
    );

    const worksheetId = getActivityItem($(e.currentTarget)).attr(idAttr);
    const $worksheetItem = $(`${learningResourcesItemSelector}[data-id="${worksheetId}"]`);
    const $favoriteIcon = $worksheetItem.find(markFavoriteItemIcon);

    // detect is worksheet already added to favorites
    const isPostMethod = !$favoriteIcon.hasClass('_active');

    // flow for logged in users
    if (IS_LOGGED_IN) {
      toggleAddWorksheetToFavorite(worksheetId, isPostMethod, () => {
        toggleUiViewOfFavoriteIcon(worksheetId, isPostMethod);
      });
      return;
    }

    // flow for not logged in users
    storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ID, worksheetId);
    storage.set(LOCAL_STORAGE_KEYS.PLACEMENT, PLACEMENTS.WORKSHEET_LIST_PAGE);
    storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION, WORKSHEET_ACTIONS.ADD_TO_FAVORITE);
    $(MODALS.WORKSHEET_IMAGE_POPUP).modal('hide');
    $websiteQuestionFavouritesModal.modal('show');
  };

  const handleMarkDone = (e) => {
    e.preventDefault();

    callGaEvents(
        () => {ga('send', 'event', 'WH_Category_list', 'completed', 'Click')},
        () => {ga('send', 'event', 'WH_Category_tile', 'completed', 'Click');}
    );

    const worksheetId = getActivityItem($(e.currentTarget)).attr(idAttr);
    const $worksheetItem = $(`${learningResourcesItemSelector}[data-id="${worksheetId}"]`);
    const $doneIcon = $worksheetItem.find(markDoneItemIcon);

    // detect is worksheet already added to favorites
    const isPostMethod = !$doneIcon.hasClass('_active');

    // flow for logged in users
    if (IS_LOGGED_IN) {
      toggleMarkWorksheetAsDone(worksheetId, isPostMethod, () => {
        toggleUiViewOfDoneIcon(worksheetId, isPostMethod);
      });
      return;
    }

    // not logged in users
    storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ID, worksheetId);
    storage.set(LOCAL_STORAGE_KEYS.PLACEMENT, PLACEMENTS.WORKSHEET_LIST_PAGE);
    storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION, WORKSHEET_ACTIONS.MARK_AS_DONE);
    $(MODALS.WORKSHEET_IMAGE_POPUP).modal('hide');
    $websiteQuestionDoneModal.modal('show');
  };

  const getWorksheetId = ($activityItem) => {
    return $activityItem.attr(idAttr);
  };

  const getWorksheetSlug = ($activityItem) => {
    return $activityItem.attr(slugAttr);
  };

  const getActivityItem = (target) => {
    return $(target).closest(learningResourcesItemSelector);
  };

  function handleRemoveAllFilters(e) {
    if (e) e.preventDefault();

    const activity = $selectedActivity ? $selectedActivity.closest(parentActivitySelector) : null;
    const subActivity = !activity || activity.data('id') === $selectedActivity.data('id') ? null : $selectedActivity;
    handleRemoveActivityFilter(activity);
    handleRemoveActivityFilter(subActivity);

    const subject = $selectedSubject ? $selectedSubject.closest(parentSubjectsSelector) : null;
    const subSubject = !subject || subject.data('id') === $selectedSubject.data('id') ? null : $selectedSubject;
    handleRemoveSubjectFilter(subSubject);
    handleRemoveSubjectFilter(subject);

    $(searchQuerySelector).val('');
    $(favoriteFilterSelector).removeClass(activeFilterClassname);
    $hideDoneMain.prop('checked', false);
    $(keyFilterSelector).removeClass(activeFilterClassname);

    if ($(onlineFilterSelector).hasClass(activeFilterClassname)) ignoreSorting = true;

    $(onlineFilterSelector).removeClass(activeFilterClassname);
    updateWorksheets(basePath);
  }

  const addSubjectLinksSearchResultListeners = function (e) {
    e.preventDefault();
    // reset activity, standard filters
    $selectedActivity = null;
    $selectedStandard = null;

    const searchUrl = $(e.currentTarget).closest(subjectFilterItem).data('searchUrl');

    $(e.currentTarget).closest(subjectFilterItem).addClass('open selected');
    updateWorksheets(searchUrl);
  };

  const removeSubjectLinksSearchResultListeners = function (e) {
    e.preventDefault();

    $selectedSubject = null;
    const url = buildUrl();
    updateWorksheets(url);
  }

  const addSubjectLinksListeners = function (e) {
    e.preventDefault();
    // reset activity, standard filters
    $selectedActivity = null;
    $selectedStandard = null;

    const $subject = $(this).closest(subjectSelector);
    const id = $subject.attr('data-id');
    const $subjectItems = $(filterItemsContainer).find(`[data-id="${id}"]`);
    const $mainListSubjectItem = $subjectItems.filter(':not(._sorted)');

    if (!$selectedSubject || $selectedSubject.data('id') !== $subject.data('id')) {
      $selectedSubject = $mainListSubjectItem;
      $subject.parents(subjectSelector).addClass(openClassName);
      $subject.addClass(openClassName).addClass(selectedClassName);
      clearCurrentSearchValue();
      const url = buildUrl();
      updateWorksheets(url);
    }
  };

  const updateSubjectFilter = (sortedItems = [], $container = null) => {
    if (!$container) return;

    const emptyText = `<div ${emptyFilterSearchDataAttr} class="learning-resources__filter-empty">No results</div>`;

    $(filterItemsSearchResultsContainer).find(emptyFilterSearchText).remove();
    if (!sortedItems.length) $(filterItemsSearchResultsContainer).append(emptyText);

    $(filterItemsContainer).addClass('hidden');

    $selectedSubject = $(selectedSubjectSelector);
    const selectedSubjectId = $selectedSubject ? $selectedSubject.attr('data-id') : null;

    let sortedItemsHtml = '';
    sortedItems.forEach((item) => {
      const id = `subject-${item.id}`;

      sortedItemsHtml +=
        `<li class="subject learning-resources__filter-list-item ${id === selectedSubjectId ? 'open selected' : ''}"
           id="${id}"
           data-slug="${item.slug}"
           data-id="${id}"
           data-search-url="${item.url}"
           data-selector="subject-filter-item"
           data-name="${capitalize(item.name)}"
       >
        <label for="${id}" class="learning-resources__filter-list-item-label">
          <a href="/printable-worksheets/${item.slug}/" class="learning-resources__filter-list-item-label-name " data-selector="subject-filter-link">
            ${capitalize(item.name)}
          </a>
          <span class="learning-resources__filter-list-item-label-close icon-cross" data-selector="remove-subject-filter-btn"></span>
        </label>
      </li>`;
    });

    $(filterItemsSearchResultsContainer).html(sortedItemsHtml)
    $(document)
      .on('click', subjectLinksSearchResultsSelector, addSubjectLinksSearchResultListeners)
      .on('click', subjectSearchResultsRemove, removeSubjectLinksSearchResultListeners);
  };

  const toggleFilterSearchIcons = (searchText = '', $searchFilterContainer = null) => {
    if (!$searchFilterContainer) return;
    const $fFilterSearchBtn = $searchFilterContainer.find(filterSearchBtn);
    const $filterClearBtn = $searchFilterContainer.find(filterSearchClearBtn);

    if (searchText.length) {
      $fFilterSearchBtn.hide();
      $filterClearBtn.show();
      return;
    }

    $fFilterSearchBtn.show();
    $filterClearBtn.hide();
  };

  const updateUiWithAppliedSearch = (searchText = '', $searchFilterContainer = null) => {
    if (!searchText || !$searchFilterContainer) return;

    const isDataReady = !!filterSubjects.subjects.length;

    const nameAttr = 'data-name';

    // clean up elements
    const $sortedItems = $searchFilterContainer.find(`.${sortedClassName}`);
    $sortedItems.remove();

    const handleApplyFilter = () => {
      const filteredItemsArray = filterSubjects.subjects.filter(item => {
        const filterItemName = item.name.toLowerCase();
        const itemDescription = item.description.toLowerCase();
        const textToSearch = filterItemName + itemDescription;
        searchText = searchText.toLowerCase();

        return textToSearch.includes(searchText);
      });

      const sortedFilterItemsArray = [...filteredItemsArray];
      sortedFilterItemsArray.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      updateSubjectFilter(sortedFilterItemsArray, $searchFilterContainer);
    };

    if (isDataReady) return handleApplyFilter();

    $.ajax({
        url: ENDPOINT.GET_SUBJECTS_FOR_FILTER,
        contentType: 'application/json',
        type: 'GET',
      }).then((response) => {
        filterSubjects = response;
        handleApplyFilter();
      }, (error) => {
        console.log(error);
      });
  };

  const onFilterSearchInputChange = (searchText = '', $searchFilterContainer = null) => {
    if (!$searchFilterContainer) return;

    // show default subjects/activity state
    if (searchText.length === 0) {
      $(filterItemsContainer).removeClass('hidden');
      $(filterItemsSearchResultsContainer).html('');

      const $emptyFilterSearchText = $searchFilterContainer.find(emptyFilterSearchText);
      $emptyFilterSearchText.remove();

      const $searchFilter = $searchFilterContainer.find(searchFilter);
      $searchFilter.removeClass(scrollableClassName);

      const $sortedItems = $searchFilterContainer.find(`.${sortedClassName}`);
      $sortedItems.remove();

      const filterType = $searchFilterContainer.data('type');
      const $currentFilterItems = $searchFilterContainer.find(`[data-selector="${filterType}-filter-item"]`);
      $currentFilterItems.removeClass(hiddenClassName);
    }

    if (searchText.length < minSymbolToSearchLength) return;
    updateUiWithAppliedSearch(searchText, $searchFilterContainer); // update UI
  };

  const callGaEvents = (eventListView, eventCardsView) => {
    if (localStorage.getItem(worksheetListView) === listViewMode) eventListView();
    if (localStorage.getItem(worksheetListView) === cardsViewMode) eventCardsView();
  };

  checkAgeRangeFilterShouldBeOpen();

  $(document)
    .on('click', collapseItemBtn, (e) => {
      const $currentItem = $(e.target).closest(collapseItem);
      const $currentContent = $($currentItem).find(collapseItemContent);

      // to close other filter on opening current filter - uncomment below
      // const $collapseContainer = $($currentItem).closest(collapseContainer);
      // const $allContent = $($collapseContainer).find(collapseItemContent);
      // const $allItems = $($collapseContainer).find(collapseItem);

      if ($currentItem.attr('data-type') === 'age') ageCollapseIsOpen = true;

      if ($currentItem.hasClass(openedClass)) {
        $currentContent.stop(true, true).slideUp(animationSpeed, () => {
          $currentItem.removeClass(openedClass);
        });

      } else {
        $currentContent.stop(true, true).slideDown(animationSpeed, () => {
          $currentItem.addClass(openedClass);
        });

        // to close other filter on opening current filter - uncomment below
        // $allContent.stop(true, true).slideUp(animationSpeed, () => {
        //   $allItems.removeClass(openedClass);
        // });
      }
    })
    .on('click', assignToClassroom, function (e) {
      const $btn = $(e.currentTarget);
      const gradeId = $btn.attr('data-grade-id');
      const categorySlug = $btn.attr('data-category-slug');
      const activityId = $btn.attr('data-activity-id');
      const activityContentType = $btn.attr('data-activity-content-type');
      const utm = $btn.attr('data-utm');

      addActivityToClassroom({activities: [{ categorySlug, gradeId, activityId, activityContentType }], utm});
    })
    .on('click', onlineFilterSelector, function (e) {
      e.preventDefault();
      handleOnlineFilterClick(e);
    })
    .on('change', sortSelector, function () {
      ignoreSorting = false;
      const url = buildUrl();
      updateWorksheets(url);
    })
    .on('click', removeFiltersSelector, handleRemoveAllFilters)
    .on('click', favoriteFilterSelector, function (e) {
      e.preventDefault();
      if (IS_LOGGED_IN) {
        handleFavoriteFilterClick(e);
        return;
      }

      storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION, WORKSHEET_ACTIONS.FAVORITE_FILTER);
      $websiteQuestionFavouritesModal.modal('show');
    })
    .on('click', keyFilterSelector, function (e) {
      e.preventDefault();
      handleAnswerKeyFilterClick(e);
    })
    .on('click', hideDoneFilterSelector, function (e) {
      e.preventDefault();
      if (IS_LOGGED_IN) {
        handleAccomplishedFilterClick(e);
        return;
      }
      storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION, WORKSHEET_ACTIONS.DONE_FILTER);
      $(MODALS.WORKSHEET_IMAGE_POPUP).modal('hide');
      $websiteQuestionDoneModal.modal('show');
    })
    .on('click', markDoneItemIcon, handleMarkDone)
    .on('click', downloadItemIconSelector, (e) => {
      e.preventDefault();
      downloadWorksheetHandler(e, WORKSHEET_ACTION_TYPES.DOWNLOAD);
    })
    // clear subject search input
    .on('click', filterSearchClearBtn, function (e) {
      SEARCH_REQUEST.text = '';
      SEARCH_REQUEST.type = null;

      const $searchFilterContainer = $(e.target).closest(searchFilterContainer);
      $searchFilterContainer.find(filterSearchInput).val('');
      onFilterSearchInputChange('', $searchFilterContainer);
      toggleFilterSearchIcons('', $searchFilterContainer);

      const $itemsContainer = $searchFilterContainer.find(filterItemsContainer);
      $itemsContainer.removeClass(scrollableClassName);

      updateUiWithAppliedSearch('', $searchFilterContainer);
    })
    // type in activity/subject search input
    .on('input', filterSearchInput, (e) => {
      const $searchFilterContainer = $(e.target).closest(searchFilterContainer);
      const searchText = e.target.value.trim();
      SEARCH_REQUEST.type = $searchFilterContainer.data('type');
      SEARCH_REQUEST.text = searchText;

      toggleFilterSearchIcons(searchText, $searchFilterContainer);

      clearTimeout(filterSearchDebounce);
      filterSearchDebounce = setTimeout(() => {
        onFilterSearchInputChange(searchText, $searchFilterContainer);
      }, filterSearchDebounceDelay);
    })
    .on('click', markFavoriteItemIcon, handleMarkFavorite)
    // change difficulty filter
    .on('change', difficultyInputsSelector, function (e) {
      // reset standards filter
      $selectedStandard = null;

      clearCurrentSearchValue();
      const url = buildUrl();
      updateWorksheets(url);
    })
    .on('change', filterInputsSelector, function () {
      clearCurrentSearchValue();
      const url = buildUrl();
      updateWorksheets(url);
    })
    .on('click', gradeLabelsSelector, function (e) {
      const $subSubjects = $(e.currentTarget).closest(subSubjectsSelector);

      if (!$subSubjects.length) {
        e.preventDefault();
        const $input = $(this).closest(filterItemSelector).find('> input');
        $input.removeAttr('checked');

        const url = buildUrl();
        updateWorksheets(url);
      }
    })
    // change grade filter
    .on('click', gradeLinksSelector, function (e) {
      e.preventDefault();
      // reset standards filter
      $selectedStandard = null;

      $(checkedAgeInputSelector).attr('checked', false);
      $(ageCollapseItem).removeClass('_opened');
      ageCollapseIsOpen = false;

      const label = $(e.currentTarget).closest('label');
      if (label) label.click();
    })
    .on('click', difficultyLinkSelector, function (e) {
      e.preventDefault();
      const label = $(e.currentTarget).closest('label');
      if (label) label.click();
    })
    .on('click', filterTagRemove, function (e) {
      e.preventDefault();

      const filterType = $(this).closest(filterTagItemSelector).attr('data-type');
      const filterId = $(this).closest(filterTagItemSelector).attr('data-id');
      const $filterItem = $('#' + filterId);

      switch (filterType) {
        case 'grade':
          $filterItem.removeAttr('checked');
          break;
        case 'age':
          $(checkedAgeInputSelector).attr('checked', false);
          break;
        case 'subject':
          handleRemoveSubjectFilter($filterItem);
          break;
        case 'standard':
          handleRemoveStandardFilter($filterItem);
          break;
        case 'activity':
          handleRemoveActivityFilter($filterItem);
          break;
        case 'answer-key':
          const $answerKey = $(keyFilterSelector);
          $answerKey.toggleClass(activeFilterClassname);
          break;
        case 'online':
          const $onlineFilter = $(onlineFilterSelector);
          if ($(onlineFilterSelector).hasClass(activeFilterClassname)) ignoreSorting = true;

          $onlineFilter.toggleClass(activeFilterClassname);
          break;
        case 'skill':
          activeSkillSlug = null;
        case 'difficulty':
          const $filterDifficultyItem = $('#' + `difficulty-${filterId.toLowerCase()}`);
          $filterDifficultyItem.removeAttr('checked');
          break;
      }

      const url = buildUrl();
      updateWorksheets(url);
    })
    // remove selected subject filter
    .on('click', subjectSelectorRemove, function (e) {
      e.preventDefault();
      clearCurrentSearchValue();
      const $subjectItem = $(this).closest(subjectFilterItem);
      handleRemoveSubjectFilter($subjectItem);

      const url = buildUrl();
      updateWorksheets(url);
    })
    // remove selected activity filter
    .on('click', removeActivityFilterBtn, function (e) {
      e.preventDefault();
      clearCurrentSearchValue();
      const $activityItem = $(this).closest(activityFilterItem);
      handleRemoveActivityFilter($activityItem);

      const url = buildUrl();
      updateWorksheets(url);
    })
    // remove selected standard filter
    .on('click', removeStandardFilterBtn, function (e) {
      e.preventDefault();
      clearCurrentSearchValue();
      const $standardItem = $(this).closest(standardFilterItem);
      handleRemoveStandardFilter($standardItem);

      const url = buildUrl();
      updateWorksheets(url);
    })
    .on('click', checkedDifficultyFilterRemove, function (e) {
      e.preventDefault();
      clearCurrentSearchValue();
      const $difficultyItem = $(this).closest(difficultyFilterItem);
      const $difficultyInput = $difficultyItem.find(difficultyInput);
      $difficultyInput.prop('checked', false);
      const url = buildUrl();
      updateWorksheets(url);
    })
    // change subject filter
    .on('click', subjectLinksSelector, addSubjectLinksListeners)
    // change activity filter
    .on('click', activityFilterLink, (e) => {
      e.preventDefault();
      const activity = $(e.target).closest(activityFilterItem);
      $selectedActivity = activity;

      // reset subject, standard
      $selectedSubject = null;
      $selectedStandard = null;

      activity.closest(activityFilterItem).addClass(openClassName);
      activity.addClass(`${selectedClassName} ${openClassName}`);

      clearCurrentSearchValue();
      const url = buildUrl();
      updateWorksheets(url);
    })
    .on('click', standardFilterLink, (e) => {
      e.preventDefault();
      const standard = $(e.target).closest(standardFilterItem);
      $selectedStandard = standard;

      // reset subject, activity, grade, difficulty filters
      $selectedSubject = null;
      $selectedActivity = null;
      $(checkedGradeInputSelector).attr('checked', false);
      $(checkedDifficultyInputSelector).attr('checked', false);

      standard.closest(standardFilterItem).addClass(openClassName);
      standard.addClass(`${selectedClassName} ${openClassName}`);

      clearCurrentSearchValue();
      const url = buildUrl(null, 'standards');
      updateWorksheets(url);
    })
    .on('submit', searchFormSelector, function (e) {
      e.preventDefault();
      const defaultSortByOption = 'relevance';
      searchString = $(searchQuerySelector).val();
      if (!$sortBySelect.val()) {
        $sortBySelect.val(defaultSortByOption);
      }

      const url = buildUrl();
      updateWorksheets(url);
      hideFilter();
    })
    .on('click tap', filtersLabelSelector, function () {
      toggleFilter();
    })
    .on('click tap', filterBgSelector, function () {
      hideFilter();
    })
    // go to interactive worksheets page
    .on('click', goToWebCheckItemIconSelector, (e) => {
      e.preventDefault();
      downloadWorksheetHandler(e, WORKSHEET_ACTION_TYPES.COMPLETE_ONLINE);
    })
    // click to worksheet title in list view mode
    .on('click', learningResourcesItemTitle, () => {
      ga('send', 'event', 'WH_Category_list', 'Open_Title', 'Click');
    })
    // go to worksheet details page
    .on('click', learningResourcesItemLink, () => {
      callGaEvents(
        () => {ga('send', 'event', 'WH_Category_list', 'Open_Image', 'Click')},
        () => {ga('send', 'event', 'WH_Category_tile', 'open', 'Click');}
      );
    })
    // toggle worksheets view to list/cards mode
    .on('click', viewToggle, () => {
      let mode = $(listViewBtn).hasClass(activeClass) ? cardsViewMode : listViewMode;
      setWorksheetsView(mode,true);
    })
    .on('click', ageFilterItem, (e) => {
      e.preventDefault();

      $(e.target).closest('li').find('>input').attr('checked', true);

      const isRangeAge = checkIsActiveRangeAge();

      if(!isRangeAge) changeAgeRangeFilterState(false);

      $(checkedGradeInputSelector).attr('checked', false);

      const url = buildUrl();
      updateWorksheets(url);
    })
    .on('click', removeAgeFilterBtn, (e) => {
      e.preventDefault();
      e.stopPropagation();
      $(e.target).closest(ageFilterItem).find('>input').attr('checked', false);
      const url = buildUrl();
      updateWorksheets(url);
    })
    .on('click', ageRangeFilterButton, (e) => {
      e.preventDefault();

      changeAgeRangeFilterState(true);

      const isRangeAgeActive = checkIsActiveRangeAge();
      const anyAgeChecked = Boolean($(checkedAgeInputSelector).attr('checked'));

      if(isRangeAgeActive || !anyAgeChecked) return;

      $(checkedAgeInputSelector).attr('checked', false);
      const url = buildUrl();
      updateWorksheets(url);
    })
    .on('click', ageRangeRemoveFilterButton, (e) => {
      e.preventDefault();

      const isRangeAgeActive = checkIsActiveRangeAge();

      changeAgeRangeFilterState(false);

      if (!isRangeAgeActive) return;

      $(checkedAgeInputSelector).attr('checked', false);
      const url = buildUrl();
      updateWorksheets(url);
    })
    .on('click', removeAgeTagFilter, (e) => {
      e.preventDefault();
      const filterId = $(e.currentTarget).closest(filterTagItemSelector).attr('data-id');
      $('#' + filterId).removeAttr('checked');

      const url = buildUrl();
      updateWorksheets(url);
    });

  let skipNextPopstateEvent = false;
  $(window).on('popstate', function (e) {
    const targetLocation = e.currentTarget.location;
    if (targetLocation.hash && targetLocation.hash.length > 3) {
      // after changing `targetLocation.hash` popstate event will be fired.
      // set `skipNextPopstateEvent` to skip that event.
      skipNextPopstateEvent = true;

      // don't reload worksheets if link with anchor was clicked (like #signUpModal)
      targetLocation.hash = '';
      return;
    }

    if (skipNextPopstateEvent) {
      skipNextPopstateEvent = false;
      return;
    }

    const url = targetLocation.pathname + targetLocation.search;
    updateWorksheets(url, {
      pushState: false
    });
  });

  const closePopupBtn = '[data-selector="monetization-popup-close"]';

  const popupHideHandler = (e) => {
    $(closePopupBtn).hide();
    $(downloadText).show();
    $(downloadLink).hide();
  };

  const popupShownHandler = (e) => {
    ga('send', 'event', 'TG_Banner', 'PopUp Shown', 'Click');

    // show close popup btn
    setTimeout(() => {
      $(closePopupBtn).show();
    }, WORKSHEET_DOWNLOAD_DELAY);
  };

  $(monetizationPopupsGlobalSelector).on('show.bs.modal', popupShownHandler);
  $(monetizationPopupsGlobalSelector).on('hide.bs.modal', popupHideHandler);

  const defineFirstKind = () => {
    //popup first kind
    const $firstKindVideoStep = $('[data-selector="first-kind-video-step"]');
    const $firstKindPurchaseStep = $('[data-selector="first-kind-purchase-step"]');
    const monetizationPopupLink = '[data-selector="monetization-popup-link"]';
    const firstKindPlan = '[data-selector="first-kind-plan"]';
    const firstKindGoToPaymentStepBtn = '[data-selector="first-kind-go-to-checkout-btn"]';
    const $firstKindGoToPaymentStepBtn = $(firstKindGoToPaymentStepBtn);

    const prevSectionButton = '[data-selector="first-kind-back-to-video-section-btn"]';
    const $prevSectionButton = $(prevSectionButton);

    let $openModal = null;

    const activeCoupon = null;
    const amountOff = 0;

    const isSpecialCouponApplied = false;
    const specialCouponTrialDuration = null;
    const isEbloxAdded = false;

    const isTrialActive = () => {
      if (!window.ableToGetTrial) return false;

      const trialDuration = $openModal.find(firstKindPlan).attr(planAttr.trialDuration);
      const isTrialDisabled = parseInt(trialDuration) === 0;
      if (isTrialDisabled) return false;

      return true;
    };

    const doSignUp = () => {
      ga('send', 'event', 'Registration', 'Go', 'Parent');

      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
          account_type: ACCOUNT_TYPES.PARENT,
          email: null,
          need_to_login: true,
          source: getUserSources('source'),
          medium: getUserSources('medium'),
          campaign: getUserSources('campaign'),
          placement: getUserSources('placement')
        })
      }).then(() => {
        window.isLoggedIn = true;
        $.getScript(DROPIN_SCRIPT_URL, handleDropinToken(dropinTokenCallback));
      }, (error) => {
        toggleButtonLoading($firstKindGoToPaymentStepBtn, false);
        toastr.error(getError(error), '', {timeOut: 3000});
      });
    };

    const dropinTokenCallback = () => {
      toggleButtonLoading($firstKindGoToPaymentStepBtn, false);
      preparePuschaseStep();
    };

    const selectSection = (sectionId) => {
      if (sectionId === section.first) {
        prepareFirstSectionUI();
        // Remove an event listener set by braintree-creating function so as
        // to prevent creating multiple event handlers in case of function call repeat
        $submitPaymentBtn.off('click');
        return;
      }

      if (sectionId === section.second) {
        prepareSecondSectionUI();
      }
    };

    const prepareFirstSectionUI = () => {
      $firstKindPurchaseStep.attr(PURCHASE_STEP_TRIGGERED_ATTRIBUTE, '');
      $firstKindPurchaseStep.hide();
      $firstKindVideoStep.show();
      $subscriptionError.hide();
      $submitPaymentBtn.hide();
      $(braintreeDropinSelector).html('');
      $planRenewCondition.text('');
    };

    const prepareSecondSectionUI = () => {
      $firstKindPurchaseStep.attr(PURCHASE_STEP_TRIGGERED_ATTRIBUTE, 'true');
      toggleButtonLoading($prevSectionButton, true);
      $firstKindVideoStep.hide();
      $planDiscountRow.hide();
      $planTrialRow.hide();
      $planRenewCondition.hide();
      $firstKindPurchaseStep.show();
    };

    const preparePuschaseStep = () => {
      $submitPaymentBtn.hide();
      selectSection(section.second);

      const offer = $openModal.find(firstKindPlan);
      const {
        planPeriod,
        planFullPrice,
        planFormattedNewPrice,
        planSlug,
        planBackUrl,
        planPeriodDisplay
      } = getSelectedOfferDetails(offer, amountOff, planAttr);
      const planTrialPeriod = (isSpecialCouponApplied && specialCouponTrialDuration)
          ? specialCouponTrialDuration
          : offer.attr(planAttr.trialDuration);
      const isTrialEnabled = isTrialActive();

      renderSelectedOfferDetails(
        planPeriod,
        planFullPrice,
        planTrialPeriod,
        planFormattedNewPrice,
        planPeriodDisplay
      );

      const {totalPriceInCents, priceFor3DSecure} = getTotalPrices(isTrialEnabled);

      setTotalPriceToUI(totalPriceInCents);

      const $dropinContainer = $openModal.find(braintreeDropinSelector);
      const dropinContainerId = '#' + $dropinContainer.attr('id');

      initBraintreeDropin(
        totalPriceInCents,
        priceFor3DSecure,
        planSlug,
        planBackUrl,
        isTrialEnabled,
        dropinContainerId
      );
    };

    const renderSelectedOfferDetails = (
      planPeriod,
      planFullPrice,
      planTrialPeriod,
      planFormattedNewPrice,
      planPeriodDisplay
    ) => {
      $planPeriod.text(capitalize(planPeriodDisplay));
      $planFullPrice.text(`${CURRENCY}${planFullPrice}`);

      if (isTrialActive()) {
        $planTrialPeriod.text(`${planTrialPeriod} days`);
        $planTrialRow.show();
        $planTotalPrice.text(`${CURRENCY}0.00`);

        if (planPeriod !== SUBSCRIPTION_PERIODS.LIFETIME) {
          const text = SUBSCRIPTION_PERIOD_MAPPING[planPeriod](planFullPrice);
          $planRenewCondition.html(text);
        }

        $planRenewCondition.show();
      } else $planTotalPrice.text(`${CURRENCY}${planFormattedNewPrice}`);
    };

    const getTotalPrices = (isTrialEnabled) => {
      const planFullPriceInCents = $openModal.find(firstKindPlan).attr(planAttr.price);
      const totalPrices = getTotalPricesInCents(
        planFullPriceInCents,
        isTrialEnabled,
        isEbloxAdded,
        activeCoupon,
        amountOff,
        shippingCountriesSelect
      );

      return totalPrices;
    };

    const setTotalPriceToUI = (totalPriceInCents) => {
      const currencyPrice = getCurrencyPrice(totalPriceInCents);
      $planTotalPrice.html(currencyPrice);
    };

    const initBraintreeDropin = (
      totalPriceInCents,
      priceFor3DSecure,
      slug,
      backUrl,
      isTrialEnabled,
      dropinContainerId
    ) => {
      if (window.isBraintreeInitilazing) return;

      if (braintree && $(dropinContainerId).length) {
        const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
        const braintreeConf = braintreeDropinConfig(totalPriceInDollars, dropinContainerId);
        const $dropinContainer = $(dropinContainerId);
        const isDropinInited = !$dropinContainer.is(':empty');
        if (isDropinInited) return;

        window.isBraintreeInitilazing = true;

        braintree.dropin.create(braintreeConf,
          function (createErr, instance) {
            if (createErr) {
              // An error in the create call is likely due to
              // incorrect configuration values or network issues.
              // An appropriate error will be shown in the UI.

              // eslint-disable-next-line no-console
              console.error('dropin create error: ', createErr);
              return;
            }

            if (instance.isPaymentMethodRequestable()) {
              // This will be true if you generated the client token
              // with a customer ID and there is a saved payment method
              // available to tokenize with that customer.
              $submitPaymentBtn.show();
            }

            $(prevSectionButton).hide();

            instance.on('paymentMethodRequestable', () => {
              $submitPaymentBtn.show();
              $subscriptionError.hide();
            });

            instance.on('noPaymentMethodRequestable', () => {
              $submitPaymentBtn.hide();
              $subscriptionError.hide();
            });

            instance.on('paymentOptionSelected', () => {
              $subscriptionError.hide();
              if (instance.isPaymentMethodRequestable()) {
                $submitPaymentBtn.show();
                return;
              }
              $submitPaymentBtn.hide();
            });

            toggleButtonLoading($prevSectionButton, false);
            toggleButtonLoading($submitPaymentBtn, false);

            $submitPaymentBtn.click(() => {
              // To prevent bot spamming we use solution from
              // https://www.thryv.com/blog/honeypot-technique/
              const $hiddenInput = $(hiddenInputSelect);
              const hiddenInputVal = $hiddenInput.val();
              if (hiddenInputVal) return;

              if (isEbloxAdded && !isFormValid($(shippingFormItemSelect))) return;

              $subscriptionError.hide();
              $prevSectionButton.hide();
              toggleButtonLoading($submitPaymentBtn, true);
              triggerAnalyticsSubmit(slug, totalPriceInDollars);

              const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
              const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

              instance.requestPaymentMethod({
                threeDSecure: threeDSecureConf
              }, function (requestPaymentMethodErr, payload) {
                if (requestPaymentMethodErr) {
                  // eslint-disable-next-line no-console
                  console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);
                  $subscriptionError.text(requestPaymentMethodErr.message);
                  $subscriptionError.show();
                  $prevSectionButton.show();
                  toggleButtonLoading($submitPaymentBtn, false);
                  return;
                }

                if (isThreeDSecureCanceled(payload)) {
                  instance.clearSelectedPaymentMethod();
                  $subscriptionError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
                  $subscriptionError.show();
                  toggleButtonLoading($submitPaymentBtn, false);
                  $prevSectionButton.show();
                  return;
                }

                // Submit payload.nonce to your server
                $.post(ENDPOINT.BUY_SUBSCRIPTION, {
                  payment_method_nonce: payload.nonce,
                  plan_slug: slug,
                  discount_id: activeCoupon,
                  source: 'main-flow-offer',
                  trial_enabled: isTrialEnabled // always make trial if it's available
                })
                  .then((response) => {
                    triggerGAEventOnCheckout();
                    triggerAnalyticsOnCheckout(response, slug, backUrl);
                  }, (errorResponse) => {
                    instance.clearSelectedPaymentMethod();
                    $subscriptionError.text('');
                    $subscriptionError.append(getError(errorResponse));
                    $subscriptionError.show();

                    toggleButtonLoading($submitPaymentBtn, false);
                  });
              });
            });
          });
      }
    };

    const triggerAnalyticsSubmit = (slug, totalPriceInDollars) => {
      ga('send', 'event', 'Registration_flow', 'SubmitPayment', 'step7');
    };

    const triggerGAEventOnCheckout = () => {
      if (isTrialActive()) {
        ga('send', 'event', 'Trial', 'Started', 'Parent');
      } else {
        ga('send', 'event', 'Subscription', 'Started', 'Parent');
      }
    };

    const triggerAnalyticsOnCheckout = (response, slug, backUrl) => {
      window.location = backUrl;
    };

    $(document)
      .on('click', monetizationPopupLink, (e) => {
        const openModal = $(e.target).closest($(monetizationPopupsGlobalSelector))[0];
        dispatchVideoEvent(openModal,'pauseVideo');
      })
      .on('click', firstKindGoToPaymentStepBtn, (e) => {
        $openModal = $(e.target).closest(monetizationPopupsGlobalSelector);
        dispatchVideoEvent($openModal[0], 'stopVideo');
        toggleButtonLoading($firstKindGoToPaymentStepBtn, true);
        if (!window.isLoggedIn) {
          doSignUp();
        } else {
          $.getScript(DROPIN_SCRIPT_URL, handleDropinToken(dropinTokenCallback));
        }
      })
      .on('click', prevSectionButton, () => {
        selectSection(section.first);
      });

    $(monetizationPopupsGlobalSelector).on('hide.bs.modal', () => {
      prepareFirstSectionUI();
      $openModal = null;
    });
  };

  const defineSubscriptionStep = () => {
    const $videoStep = $('[data-selector="monetization-popup-video-step"]');
    const $subscriptionStep = $('[data-selector="monetization-popup-subscription-step"]');
    const isSubscriptionKind = !!$subscriptionStep.length;
    const goToSubscriptionStepSelector = '[data-selector="go-to-subscription-step-btn"]';

    if (!isSubscriptionKind) return;

    let $openModal = null;

    const popupDialogSelector = '[data-selector="monetization-popup-dialog"]';
    const subscriptionFirstStepClass = '_subscription-first-step';

    const checkoutTextAttr = {
      trial: 'data-trial-text',
      noTrial: 'data-no-trial-text'
    };

    const $firstSection = $('[data-selector="1-subscription-step-monetization-popup"]');
    const $secondSection = $('[data-selector="2-subscription-step-monetization-popup"]');

    // subscription block (first step)
    const planInput = '[data-selector="monetization-popup-subscription-plan-input"]';
    const selectedPlanInput = `${planInput}:checked`;
    const goToPaymentStepBtn = '[data-selector="monetization-popup-go-to-checkout-btn"]';
    const $goToPaymentStepBtn = $(goToPaymentStepBtn);
    const prevSectionButton = '[data-selector="monetization-popup-back-to-first-section-btn"]';
    const $prevSectionButton = $(prevSectionButton);

    // coupon
    const toggleCouponBlockBtn = '[data-selector="monetization-popup-toggle-coupon-block-btn"]';
    const $toggleCouponBlockBtn = $(toggleCouponBlockBtn);
    const $couponBlock = $('[data-selector="monetization-popup-coupon-block"]');
    const $couponInputSection = $('[data-selector="monetization-popup-coupon-input-section"]');
    const couponForm = '[data-selector="monetization-popup-coupon-form"]';
    const $couponForm = $(couponForm);
    const couponInput = '[data-selector="monetization-popup-enter-coupon-input"]';
    const $couponInput = $couponForm.find(couponInput);
    const applyCoupon = '[data-selector="monetization-popup-apply-coupon-btn"]';
    const $applyCoupon = $couponForm.find(applyCoupon);
    const $couponError = $('[data-selector="monetization-popup-coupon-error"]');
    const removeCoupon = '[data-selector="monetization-popup-remove-coupon-btn"]';
    const $removeCoupon = $couponForm.find(removeCoupon);
    const $couponMessage = $couponForm.find('[data-selector="monetization-popup-coupon-message"]');
    const $licenseActivationCodeModal = $('#licenseActivationCode');
    const couponBlockParams = {
      activeClass: '_active',
      emptyCouponClass: '_empty',
      showCouponText: 'Enter coupon code',
      hideCouponText: 'Hide coupon code'
    };
    let activeCoupon = null;
    let amountOff = 0;

    let isSpecialCouponApplied = false;
    let specialCouponTrialDuration = null;
    let removedSubscriptions = [];
    const isEbloxAdded = false;


    const doCheckForPreselectedPlan = () => {
      $(monetizationPopupsGlobalSelector).each((index, element) => {
        const $popupPlanInput = $(element).find(planInput);
        if ($popupPlanInput.is(':checked')) return;

        $popupPlanInput.first().prop('checked', true);
      });
    };

    const toggleIsCouponInputSection = () => {
      const isCouponInputAvailable = $(selectedPlanInput).attr(planAttr.isCouponEnabled) === 'True';
      isCouponInputAvailable ? $couponInputSection.show() : $couponInputSection.hide();
    };

    const setCheckoutBtnTitleNew = () => {
      const checkoutBtnTitle = $(selectedPlanInput).attr(planAttr.submitText);
      $goToPaymentStepBtn.text(checkoutBtnTitle);
    };

    const initFirstSectionUI = () => {
      toggleIsCouponInputSection();
      toggleApplyCouponBtn($couponInput, $applyCoupon, couponBlockParams);
      setCheckoutBtnTitleNew();
    };

    const onSubscriptionStepLoad = () => {
      doCheckForPreselectedPlan();
      initFirstSectionUI();
    };

    const isTrialActive = () => {
      if (!window.ableToGetTrial) return false;

      if (isSpecialCouponApplied) return true;

      const trialDuration = $openModal.find(selectedPlanInput).attr(planAttr.trialDuration);
      const isTrialDisabled = parseInt(trialDuration) === 0;
      if (isTrialDisabled) return false;

      if (activeCoupon) return false;

      return true;
    };

    const checkCouponApplicability = () => {
      const planSlug = $(selectedPlanInput).attr(planAttr.slug);
      const itemIndex = removedSubscriptions.indexOf(planSlug);
      const isInvalid = removedSubscriptions[itemIndex];

      if (isInvalid) {
        $goToPaymentStepBtn.prop('disabled', true);
        $couponError.show();
      } else {
        $goToPaymentStepBtn.prop('disabled', false);
        $couponError.hide();
      }

      return !isInvalid;
    };

    const clearComponentVariables = () => {
      activeCoupon = null;
      amountOff = 0;
      isSpecialCouponApplied = false;
      specialCouponTrialDuration = null;
      removedSubscriptions = [];
    };

    const prepareFirstSectionUI = () => {
      $openModal.find(popupDialogSelector).addClass(subscriptionFirstStepClass);
      $secondSection.attr(PURCHASE_STEP_TRIGGERED_ATTRIBUTE, '');
      $secondSection.hide();
      $firstSection.show();
      $subscriptionError.hide();
      $submitPaymentBtn.hide();
      $(braintreeDropinSelector).html('');
      $planRenewCondition.text('');
    };

    const prepareSecondSectionUI = () => {
      $openModal.find(popupDialogSelector).removeClass(subscriptionFirstStepClass);
      $secondSection.attr(PURCHASE_STEP_TRIGGERED_ATTRIBUTE, 'true');
      toggleButtonLoading($prevSectionButton, true);
      $firstSection.hide();
      $planDiscountRow.hide();
      $planTrialRow.hide();
      $planRenewCondition.hide();
      $secondSection.show();
    };

    const selectSection = (sectionId) => {
      if (sectionId === section.first) {
        prepareFirstSectionUI();
        // Remove an event listener set by braintree-creating function so as
        // to prevent creating multiple event handlers in case of function call repeat
        $submitPaymentBtn.off('click');
        return;
      }

      if (sectionId === section.second) {
        prepareSecondSectionUI();
      }
    };

    const renderSelectedOfferDetails = (
      planPeriod,
      planFullPrice,
      planTrialPeriod,
      planFormattedNewPrice,
      planPeriodDisplay
    ) => {
      $planPeriod.text(capitalize(planPeriodDisplay));
      $planFullPrice.text(`${CURRENCY}${planFullPrice}`);

      if (activeCoupon && amountOff > 0) {
        $planDiscount.text(`${CURRENCY}${amountOff}`);
        $planDiscountRow.show();
      }

      if (isTrialActive()) {
        $planTrialPeriod.text(`${planTrialPeriod} days`);
        $planTrialRow.show();
        $planTotalPrice.text(`${CURRENCY}0.00`);

        if (planPeriod !== SUBSCRIPTION_PERIODS.LIFETIME) {
          const text = SUBSCRIPTION_PERIOD_MAPPING[planPeriod](planFullPrice);
          $planRenewCondition.html(text);
        }

        $planRenewCondition.show();
      } else $planTotalPrice.text(`${CURRENCY}${planFormattedNewPrice}`);
    };

    const getTotalPrices = (isTrialEnabled) => {
      const planFullPriceInCents = $openModal.find(selectedPlanInput).attr(planAttr.price);
      const totalPrices = getTotalPricesInCents(
        planFullPriceInCents,
        isTrialEnabled,
        isEbloxAdded,
        activeCoupon,
        amountOff,
        shippingCountriesSelect
      );

      return totalPrices;
    };

    const setTotalPriceToUI = (totalPriceInCents) => {
      const currencyPrice = getCurrencyPrice(totalPriceInCents);
      $planTotalPrice.html(currencyPrice);
    };

    const initBraintreeDropin = (
      totalPriceInCents,
      priceFor3DSecure,
      slug,
      backUrl,
      isTrialEnabled,
      dropinContainerId
    ) => {
      if (braintree && $(dropinContainerId).length) {
        const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
        const braintreeConf = braintreeDropinConfig(totalPriceInDollars, dropinContainerId);

        braintree.dropin.create(braintreeConf,
          function (createErr, instance) {
            if (createErr) {
              // An error in the create call is likely due to
              // incorrect configuration values or network issues.
              // An appropriate error will be shown in the UI.

              // eslint-disable-next-line no-console
              console.error('dropin create error: ', createErr);
              return;
            }

            if (instance.isPaymentMethodRequestable()) {
              // This will be true if you generated the client token
              // with a customer ID and there is a saved payment method
              // available to tokenize with that customer.
              $submitPaymentBtn.show();
            }

            instance.on('paymentMethodRequestable', () => {
              $submitPaymentBtn.show();
              $subscriptionError.hide();
            });

            instance.on('noPaymentMethodRequestable', () => {
              $submitPaymentBtn.hide();
              $subscriptionError.hide();
            });

            instance.on('paymentOptionSelected', () => {
              $subscriptionError.hide();
              if (instance.isPaymentMethodRequestable()) {
                $submitPaymentBtn.show();
                return;
              }
              $submitPaymentBtn.hide();
            });

            toggleButtonLoading($prevSectionButton, false);
            toggleButtonLoading($submitPaymentBtn, false);

            $submitPaymentBtn.click(() => {
              // To prevent bot spamming we use solution from
              // https://www.thryv.com/blog/honeypot-technique/
              const $hiddenInput = $(hiddenInputSelect);
              const hiddenInputVal = $hiddenInput.val();
              if (hiddenInputVal) return;

              if (isEbloxAdded && !isFormValid($(shippingFormItemSelect))) return;

              $subscriptionError.hide();
              $prevSectionButton.hide();
              toggleButtonLoading($submitPaymentBtn, true);
              triggerAnalyticsSubmit(slug, totalPriceInDollars);

              const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
              const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

              instance.requestPaymentMethod({
                threeDSecure: threeDSecureConf
              }, function (requestPaymentMethodErr, payload) {
                if (requestPaymentMethodErr) {
                  // eslint-disable-next-line no-console
                  console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);
                  $subscriptionError.text(requestPaymentMethodErr.message);
                  $subscriptionError.show();
                  $prevSectionButton.show();
                  toggleButtonLoading($submitPaymentBtn, false);
                  return;
                }

                if (isThreeDSecureCanceled(payload)) {
                  instance.clearSelectedPaymentMethod();
                  $subscriptionError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
                  $subscriptionError.show();
                  toggleButtonLoading($submitPaymentBtn, false);
                  $prevSectionButton.show();
                  return;
                }

                // Submit payload.nonce to your server
                $.post(ENDPOINT.BUY_SUBSCRIPTION, {
                  payment_method_nonce: payload.nonce,
                  plan_slug: slug,
                  discount_id: activeCoupon,
                  source: 'main-flow-offer',
                  trial_enabled: isTrialEnabled // always make trial if it's available
                })
                  .then((response) => {
                    triggerGAEventOnCheckout();
                    triggerAnalyticsOnCheckout(response, slug, backUrl);
                  }, (errorResponse) => {
                    instance.clearSelectedPaymentMethod();
                    $subscriptionError.text('');
                    $subscriptionError.append(getError(errorResponse));
                    $subscriptionError.show();

                    toggleButtonLoading($submitPaymentBtn, false);
                  });
              });
            });
          });
      }
    };

    const triggerAnalyticsSubmit = (slug, totalPriceInDollars) => {
      ga('send', 'event', 'Registration_flow', 'SubmitPayment', 'step7');
    };

    const triggerGAEventOnCheckout = () => {
      if (isTrialActive()) {
        ga('send', 'event', 'Trial', 'Started', 'Parent');
      } else {
        ga('send', 'event', 'Subscription', 'Started', 'Parent');
      }
    };

    const triggerAnalyticsOnCheckout = (response, slug, backUrl) => {
      window.location = backUrl;
    };

    $(document)
      .on('click', goToSubscriptionStepSelector, () => {
        dispatchVideoEvent($openModal[0], 'stopVideo');
        $openModal.find(popupDialogSelector).addClass(subscriptionFirstStepClass);
        $videoStep.hide();
        $(monetizationPopupContentSelector).addClass(subscriptionStepClass);
        $subscriptionStep.show();
      })
      // on change plan
      .on('change', planInput, () => {
        toggleIsCouponInputSection();
        setCheckoutBtnTitleNew();
        checkCouponApplicability();
      })
      // on change coupon input value
      .on('input', couponInput, () => {
        $couponInput.parsley().reset();
        toggleApplyCouponBtn($couponInput, $applyCoupon, couponBlockParams);
      })
      // apply coupon
      .on('click', applyCoupon, (e) => {
        e.preventDefault();
        // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
        const hiddenInputVal =
          $('[data-selector="monetization-popup-coupon-check-special-input"]').val();
        if (hiddenInputVal) return;

        if (!isFormValid($couponForm)) return;

        const coupon = $couponInput.val().trim();
        $couponError.hide();

        if (coupon) {
          toggleButtonLoading($applyCoupon, true);

          // check is coupon a distributor code
          doCheckIsCouponDistributorCode(coupon)
            .then(() => {
              toggleButtonLoading($applyCoupon, false);
              $licenseActivationCodeModal.modal('show');
            }, () => {
              toggleButtonLoading($applyCoupon, false);
            });

          $.post(ENDPOINT.COUPON_VALIDATE, {
            coupon: coupon
          }).then((response) => {
            toggleButtonLoading($applyCoupon, false);

            activeCoupon = coupon;
            amountOff = response.amount_off;
            removedSubscriptions = response.exclude_subscriptions;
            isSpecialCouponApplied = response.has_trial;
            specialCouponTrialDuration = response.trial_duration;

            const isCouponValid = checkCouponApplicability();
            $applyCoupon.prop('disabled', false);
            $couponInput.parsley().removeError('coupon');
            $couponInput.prop('disabled', true);
            $applyCoupon.hide();
            $removeCoupon.show();
            $couponMessage.html(response.description);
            $couponMessage.show();

            if (isCouponValid) ga('send', 'event', 'Button', 'CouponApplied', 'Parent');

            setCheckoutBtnTitle($goToPaymentStepBtn, isTrialActive(), checkoutTextAttr);
          }, (error) => {
            toggleButtonLoading($applyCoupon, false);

            activeCoupon = null;
            $couponMessage.empty();
            $couponMessage.hide();
            const errorObject = JSON.parse(error.responseText);

            if (errorObject.error) {
              $couponInput.parsley().removeError('coupon');
              $couponInput.parsley().addError('coupon', {
                message: errorObject.error
              });
            }
          });
        }
      })
      // remove coupon applied to the selected plan
      .on('click', removeCoupon, (e) => {
        e.preventDefault();

        clearComponentVariables();
        $couponInput.parsley().reset();
        $couponInput.prop('disabled', false);
        $couponInput.val('');
        $applyCoupon.show();
        $removeCoupon.hide();
        $couponMessage.empty();
        $couponMessage.hide();
        checkCouponApplicability();
      })
      // show/hide apply coupon form
      .on('click', toggleCouponBlockBtn, (e) => {
        e.preventDefault();
        toggleCouponBlock($couponBlock, $toggleCouponBlockBtn, couponBlockParams);
      })
      // go to first step (select plan)
      .on('click', prevSectionButton, () => {
        selectSection(section.first);
      });

    $(monetizationPopupsGlobalSelector).on('show.bs.modal', (e) => {
      $openModal = $(e.target).closest(monetizationPopupsGlobalSelector);
    });
    $(monetizationPopupsGlobalSelector).on('hide.bs.modal', () => {
      prepareFirstSectionUI();
      $openModal = null;
    });

    onSubscriptionStepLoad();
  };

  // start download worksheet if monetization popup closed
  $(monetizationPopupsGlobalSelector).on('hide.bs.modal', () => {
    const worksheetId = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ID, true);
    dispatchCustomEvent(WEBSITE_CUSTOM_EVENTS.DOWNLOAD_WORKSHEET, {worksheetId});

    toggleMarkWorksheetAsDone(worksheetId, true, () => {
      toggleUiViewOfDoneIcon(worksheetId, true);
    });
  });

  // on page load we need to check is user allowed to [download worksheet pdf] or [open interactive worksheet]
  const isCheckPdfStatus = storage.get(LOCAL_STORAGE_KEYS.CHECK_AVAILABILITY_OF_PDF, 'true');

  if (isCheckPdfStatus === 'true') {
    const worksheetId = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ID, true);
    const worksheetSlug = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_SLUG, true);
    const action = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION);

    switch(action) {
      case WORKSHEET_ACTIONS.INIT_WORKSHEET_DOWNLOAD:
        const doDownload = () => {
          toggleMarkWorksheetAsDone(worksheetId, true);
          dispatchCustomEvent(WEBSITE_CUSTOM_EVENTS.DOWNLOAD_WORKSHEET, {worksheetId});

          toggleMarkWorksheetAsDone(worksheetId, true, () => {
            toggleUiViewOfDoneIcon(worksheetId, true);
          });
        };
        handlePdfDownload(doDownload);
        break;
      case WORKSHEET_ACTIONS.GO_TO_WORKSHEET_CHECK_IN_TGC:
        const goToOnlineWorksheet = () => {
          toggleMarkWorksheetAsDone(worksheetId, true, () => {
            toggleUiViewOfDoneIcon(worksheetId, true);
          });
          redirectToCompleteOnlineInTGC(worksheetSlug);
        };

        handleCompleteOnline(goToOnlineWorksheet);
        break;
    }
  }

  defineFirstKind();
  defineSubscriptionStep();
});

/* eslint-enable */
