import {MODALS} from '../config';

$(document).ready(function () {
  const confirmRegistrationBtnSelector = '[data-selector="confirm-registration"]';
  const $websiteAccountTypeModal = $(MODALS.ACCOUNT_TYPE_MODAL);
  const $websiteQuestionFavouritesModal = $(MODALS.WEBSITE_QUESTION_FAVOURITES_MODAL);
  const $websiteQuestionDoneModal = $(MODALS.WEBSITE_QUESTION_DONE_MODAL);

  $(document)
    .on('click', confirmRegistrationBtnSelector, () => {
      $websiteQuestionDoneModal.modal('hide');
      $websiteQuestionFavouritesModal.modal('hide');

      $(MODALS.WORKSHEET_IMAGE_POPUP).modal('hide');
      $websiteAccountTypeModal.modal('show');
    });
});
