window.initRelatedArticlesSlickSlider = (function () {
  return function () {
    const $articlesSlider = $('[data-selector="related-articles-slider"]');
    if ($articlesSlider && $articlesSlider.length) {
      $articlesSlider.not('.slick-initialized').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        swipeToSlide: true,
        centerPadding: '2%',
        responsive: [{
          breakpoint: 1200,
          settings: {
            slidesToShow: 2
          }
        }, {
          breakpoint: 1028,
          settings: {
            slidesToShow: 2
          }
        }, {
          breakpoint: 991,
          settings: {
            slidesToShow: 2
          }
        }, {
          breakpoint: 767,
          settings: {
            centerMode: true,
            slidesToShow: 1
          }
        }]
      });
    }
  };
})();

window.initRelatedArticlesSlickSlider();
