import {
  deleteUrlParam,
  getError,
  getUrlParam,
  toggleButtonLoading
} from '../utils';
import {
  ACCOUNT_TYPES,
  ENDPOINT,
  GET_PARAMS,
  MODALS
} from '../config';

$(document).ready(() => {
  const downloadLimitBtn = '[data-selector="download-limit-form-button"]';
  const $downloadLimitBtn = $(downloadLimitBtn);

  const $downloadLimitForm = $('[data-selector="download-limit-form"]');
  const $downloadLimitEmail = $('[data-selector="download-limit-form-email"]');
  const $downloadLimitPassword = $('[data-selector="download-limit-form-password"]');
  const $downloadLimitMailingCheckBox = $('[data-selector="download-limit-mailing-checkbox"]');
  const downloadLimitPolicyCheckBox = '[data-selector="download-limit-policy-checkbox"]';
  const downloadLimitError = $('[data-selector="download-limit-error"]');
  const modalRegistrationPlacement = 'data-placement';

  const $downloadWorksheetBtn = $('[data-selector="download-worksheet-link"]');


  if (getUrlParam(GET_PARAMS.DOWNLOAD_POPUP_LIMIT) !== 'true') return;
  if (window.isLoggedIn) return;

  $(MODALS.WEBSITE_PLANS_MODAL).modal('show');
  ga('send', 'event', 'Worksheets_Free_Acc', 'PopUp shown', 'Click');

  const getUserSources = (key) => {
    if (!key) return '';
    if (!window.sourcesOfUser) return '';
    if (!window.sourcesOfUser.hasOwnProperty(key)) return '';

    const result = window.sourcesOfUser[key];
    if (result === 'None') return null;

    return result;
  };

  const getParentSignUpData = () => {
    const email = $downloadLimitEmail.val();
    const password = $downloadLimitPassword.val();
    const isSubscribedToMailing = $downloadLimitMailingCheckBox.prop('checked');

    const data = {
      account_type: ACCOUNT_TYPES.PARENT,
      email: email,
      password: password,
      subscribe_to_emails: isSubscribedToMailing,
      source: getUserSources('source'),
      medium: getUserSources('medium'),
      campaign: getUserSources('campaign'),
      placement: $downloadLimitEmail.attr(modalRegistrationPlacement)
    };

    return data;
  };

  const toggleSubmitBtn = () => {
    $downloadLimitBtn.prop('disabled', !$(downloadLimitPolicyCheckBox).is(':checked'));
  };

  toggleSubmitBtn();

  $(document)
    .on('change', downloadLimitPolicyCheckBox, () => {
      toggleSubmitBtn();
    })
    .on('click', downloadLimitBtn, (e) => {
      e.preventDefault();

      // clear error
      downloadLimitError.html();
      downloadLimitError.hide();

      // validate form
      const downloadLimitFormParsley = $downloadLimitForm.parsley();
      downloadLimitFormParsley.validate();
      if (!downloadLimitFormParsley.isValid()) return;

      toggleButtonLoading($downloadLimitBtn, true);

      // submit sign up request
      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify(getParentSignUpData())
      }).then(() => {
        ga('send', 'event', 'Worksheets_Free_Acc', 'Subscribe Button', 'Click');

        const hrefAtt = 'data-file-href';

        let worksheetLink = $downloadWorksheetBtn.attr(hrefAtt);
        if (!worksheetLink) {
          worksheetLink = $('[data-selector="learning-resources-download"]').attr(hrefAtt);
        }

        if (worksheetLink) window.open(worksheetLink, '_blank');

        deleteUrlParam(GET_PARAMS.DOWNLOAD_POPUP_LIMIT);
        window.location.reload();
      }, (error) => {
        toggleButtonLoading($downloadLimitBtn, false);
        const errorText = getError(error);
        downloadLimitError.html(errorText);
        downloadLimitError.show();
      });
    });
});
