import {WEBSITE_CUSTOM_EVENTS} from '../config';

$(document).ready(function () {
  const $collectionListPartial = $('[data-selector="collections-list-partial"]');
  if (!$collectionListPartial.length) return;

  ga('send', 'event', 'Collections', 'Show', 'Load');

  const basePath = '/collections/';

  const $seoDescriptionContainer = $('[data-selector="seo-description-container"]');
  const seoDescription = '[data-selector="seo-description"]';
  const $seoDescriptionBlock = $(seoDescription);
  const filterDescriptionSelector = '[data-selector="filter-description"]';

  const collectionBlock = '[data-selector="collection-block"]';
  const collectionList = '[data-selector*="collection-list"]';

  const videosSubtitle = '[data-selector="videos-subtitle"]';
  const worksheetsSubtitle = '[data-selector="worksheets-subtitle"]';
  const lessonsSubtitle = '[data-selector="lessons-subtitle"]';
  const gamesSubtitle = '[data-selector="games-subtitle"]';

  const lessonsHolder = '[data-selector*="lessons-holder"]';
  const gamesHolder = '[data-selector*="games-holder"]';
  const videosHolder = '[data-selector*="videos-holder"]';
  const worksheetsHolder = '[data-selector*="worksheets-holder"]';
  const storytimeHolder = '[data-selector*="storytime-holder"]';

  const searchFormSelector = '#search-form';
  const searchQuerySelector = '#search-form .search-query';
  const checkedGradeInputSelector = '#grade-items input[name=grade]:checked';
  const parentSubjectsSelector = '#subject-items > .subject';
  const spinnerSelector = '[data-selector="spinner"]';
  const h1TitleWrapper = '[data-selector="h1-title-wrapper"]';
  const h1Title = '[data-selector="h1-title"]';
  const filterSelector = '#filter';
  const removeFiltersSelector = '#remove-filters, #remove-filters-mob';
  const filterInputsSelector = '#grade-items input[type=radio]';
  const gradeLabelsSelector = '#grade-items input[type=radio]:checked + label';
  const subSubjectsSelector = '.learning-resources__filter-list-item-sub-subject-items';
  const gradeLinksSelector = '#grade-items a';
  const filterItemSelector = '.learning-resources__filter-list-item';
  const subjectLinksSelector = '#subject-items .learning-resources__filter-list-item-label-name';
  const subjectSelectorRemove =
    '#subject-items .learning-resources__filter-list-item-label > .icon-cross';
  const filterTagRemove = '#filter-tags > .filter-tags__item > .icon-cross';
  const subjectSelector = '.subject';
  const selectedSubjectSelector = '.subject.selected';
  const openClassName = 'open';
  const selectedClassName = 'selected';
  const activeClassName = 'active';
  const filterBgSelector = '#filter-inner-close';
  const filtersLabelSelector = '#filters-mob-open';
  const sortSelector = '#sort-by';
  const filterTagsSelector = '#filter-tags';
  const filterTagItemSelector = '.filter-tags__item';

  const $sortBySelect = $(sortSelector);

  $sortBySelect.select2({
    minimumResultsForSearch: -1
  });
  const $filterTags = $(filterTagsSelector);
  let searchString = $(searchQuerySelector).val();
  let selectedSubject = $(selectedSubjectSelector);

  selectedSubject = selectedSubject.length ? selectedSubject : null;

  const initSliders = () => {
    $(collectionList).not('.slick-initialized').slick({
      dots: false,
      speed: 300,
      slidesToShow: 1,
      prevArrow:'<div class="collection__scroll-btn _left"></div>',
      nextArrow:'<div class="collection__scroll-btn _right"></div>',
      infinite: false,
      centerMode: false,
      variableWidth: true
    });
  };

  initSliders();

  const buildUrl = function (page) {
    // populate data from checked items
    const grade = $(checkedGradeInputSelector).val();
    let subject = selectedSubject ? selectedSubject.closest(parentSubjectsSelector) : null;
    const subSubject = !subject || subject.data('id') === selectedSubject.data('id')
      ? null
      : selectedSubject.data('slug');
    subject = subject ? subject.data('slug') : null;

    // construct url
    let url = basePath;
    if (grade) url += grade + '/';
    if (subject) url += subject + '/';
    if (subSubject) url += subSubject + '/';

    let query = [];
    if (searchString) query.push('search=' + searchString);
    if (page && page !== 1) query.push('page=' + page);
    query = query.join('&');

    if (query) {
      url += '?' + query;
    }

    return url;
  };

  const defaultOptions = {
    pushState: true
  };

  const $filter = $(filterSelector);

  let ajaxRequest = null;

  const toggleCollectionBlock = (newContent, $holder) => {
    $holder.removeClass('slick-initialized slick-slider');

    if (newContent && newContent.trim().length) {
      $holder.html(newContent);
      $holder.closest(collectionBlock).show();
    } else {
      $holder.closest(collectionBlock).hide();
    }
  };

  const updateCollections = function (url, options) {
    $(spinnerSelector).show();

    options = $.extend({}, defaultOptions, options);
    ajaxRequest = $.ajax({
      type: 'GET',
      url: url,
      contentType: 'text/partial_html',
      data: {
        // The `partial` parameter helps to build a unique URL for a partial page.
        // The server will use the URL to fetch a page from the cache.
        partial: true,
      },
      cache: true,
      beforeSend: function () {
        if (ajaxRequest) {
          ajaxRequest.abort();
        }
      },
      success: function (data) {
        const $newPage = $('<div>').html(data);

        // update page titles
        const $h1TitleWrapper = $newPage.find(h1TitleWrapper);
        const newMetaTitle = $h1TitleWrapper.attr('data-meta-title');
        const newH1Title = $h1TitleWrapper.attr('data-h1-title');

        document.title = newMetaTitle;
        $(h1Title).html(newH1Title);

        // update collection items
        const newLessons = $newPage.find(lessonsHolder).html();
        const newGames = $newPage.find(gamesHolder).html();
        const newVideos = $newPage.find(videosHolder).html();
        const newStorytimes = $newPage.find(storytimeHolder).html();
        const newWorksheets = $newPage.find(worksheetsHolder).html();

        toggleCollectionBlock(newLessons, $(lessonsHolder));
        toggleCollectionBlock(newGames, $(gamesHolder));
        toggleCollectionBlock(newVideos, $(videosHolder));
        toggleCollectionBlock(newStorytimes, $(storytimeHolder));
        toggleCollectionBlock(newWorksheets, $(worksheetsHolder));

        // update seo texts
        const filterDescription = $newPage.find(filterDescriptionSelector).html();
        $(filterDescriptionSelector).html(filterDescription);

        const seoDescriptionBlock = $newPage.find(seoDescription).html().trim();
        const contentClass = '_has-content';

        if (seoDescriptionBlock) {
          $seoDescriptionBlock.html(seoDescriptionBlock);
          $seoDescriptionContainer.addClass(contentClass);
        } else {
          $seoDescriptionBlock.html('');
          $seoDescriptionContainer.removeClass(contentClass);
        }

        // update subtitles
        const newVideosSubtitle = $newPage.find(videosSubtitle).html();
        const newWorksheetsSubtitle = $newPage.find(worksheetsSubtitle).html();
        const newLessonsSubtitle = $newPage.find(lessonsSubtitle).html();
        const newGamesSubtitle = $newPage.find(gamesSubtitle).html();

        $(videosSubtitle).html(newVideosSubtitle);
        $(worksheetsSubtitle).html(newWorksheetsSubtitle);
        $(lessonsSubtitle).html(newLessonsSubtitle);
        $(gamesSubtitle).html(newGamesSubtitle);

        // update filters
        const newFilter = $newPage.find(filterSelector).html();
        const newFilterTags = $newPage.find(filterTagsSelector).html();
        $filter.html(newFilter);
        $filterTags.html(newFilterTags);

        searchString = $(searchQuerySelector).val();

        initSliders();
        $(window).scrollTop(0);

        ajaxRequest = null;

        document.dispatchEvent(new CustomEvent(WEBSITE_CUSTOM_EVENTS.RELOAD_HTMX_ADS_BANNERS));

        $(spinnerSelector).hide();
      },
      error: function () {
        ajaxRequest = null;
        $(spinnerSelector).hide();
      }
    });
    if (options.pushState) {
      if (window.history && typeof window.history.pushState === 'function') {
        window.history.pushState(null, null, url);
      }
    }
  };

  function clearCurrentSearchValue() {
    $(searchQuerySelector).val('');
    searchString = '';
  }

  function showFilter() {
    $(filterSelector).addClass(activeClassName);
    $(filterBgSelector).addClass(activeClassName);
  }

  function hideFilter() {
    $(filterSelector).removeClass(activeClassName);
    $(filterBgSelector).removeClass(activeClassName);
  }

  function toggleFilter() {
    if ($(filterSelector).hasClass(activeClassName)) {
      hideFilter();
    } else {
      showFilter();
    }
  }

  function handleRemoveSubjectFilter(subject) {
    if (!subject) return;
    const subjectId = subject.data('id');

    const parentSubject = subject.closest(`.subject:not(#${subjectId})`);

    if (parentSubject.length) {
      selectedSubject = parentSubject;
      parentSubject.parents(subjectSelector).addClass(openClassName);
      parentSubject.addClass(openClassName).addClass(selectedClassName);
    } else {
      selectedSubject = null;
    }
  }

  function handleRemoveAllFilters(e) {
    if (e) e.preventDefault();

    const subject = selectedSubject ? selectedSubject.closest(parentSubjectsSelector) : null;
    const subSubject = !subject || subject.data('id') === selectedSubject.data('id')
      ? null
      : selectedSubject;
    handleRemoveSubjectFilter(subSubject);
    handleRemoveSubjectFilter(subject);

    $(searchQuerySelector).val('');
    updateCollections(basePath);
  }

  $(document)
    .on('change', sortSelector, function () {
      const url = buildUrl();
      updateCollections(url);
    })
    .on('click', removeFiltersSelector, handleRemoveAllFilters)
    .on('change', filterInputsSelector, function () {
      clearCurrentSearchValue();
      const url = buildUrl();
      updateCollections(url);
    })
    .on('click', gradeLabelsSelector, function (e) {
      const $subSubjects = $(e.currentTarget).closest(subSubjectsSelector);

      if (!$subSubjects.length) {
        e.preventDefault();
        const $input = $(this).closest(filterItemSelector).find('> input');
        $input.removeAttr('checked');

        const url = buildUrl();
        updateCollections(url);
      }
    })
    .on('click', gradeLinksSelector, function (e) {
      e.preventDefault();
      const label = $(e.currentTarget).closest('label');
      if (label) label.click();
    })
    .on('click', filterTagRemove, function (e) {
      e.preventDefault();

      const filterType = $(this).closest(filterTagItemSelector).attr('data-type');
      const filterId = $(this).closest(filterTagItemSelector).attr('data-id');
      const $filterItem = $('#' + filterId);

      switch(filterType) {
        case 'grade':
          $filterItem.removeAttr('checked');
          break;
        case 'subject':
          handleRemoveSubjectFilter($filterItem);
          break;
      }

      const url = buildUrl();
      updateCollections(url);
    })
    .on('click', subjectSelectorRemove, function (e) {
      e.preventDefault();
      clearCurrentSearchValue();
      const subject = $(this).closest(subjectSelector);
      handleRemoveSubjectFilter(subject);

      const url = buildUrl();
      updateCollections(url);
    })
    .on('click', subjectLinksSelector, function (e) {
      e.preventDefault();
      const subject = $(this).closest(subjectSelector);
      if (!selectedSubject || selectedSubject.data('id') !== subject.data('id')) {
        selectedSubject = subject;
        subject.parents(subjectSelector).addClass(openClassName);
        subject.addClass(openClassName).addClass(selectedClassName);
        clearCurrentSearchValue();
        const url = buildUrl();
        updateCollections(url);
      }
    })
    .on('submit', searchFormSelector, function (e) {
      e.preventDefault();
      const defaultSortByOption = 'relevance';
      searchString = $(searchQuerySelector).val();
      if (!$sortBySelect.val()) {
        $sortBySelect.val(defaultSortByOption);
      }

      const url = buildUrl();
      updateCollections(url);
      hideFilter();
    })
    .on('click tap', filtersLabelSelector, function () {
      toggleFilter();
    })
    .on('click tap', filterBgSelector, function () {
      hideFilter();
    });

  let skipNextPopstateEvent = false;
  $(window).on('popstate', function (e) {
    const targetLocation = e.currentTarget.location;
    if (targetLocation.hash && targetLocation.hash.length > 3) {
      // after changing `targetLocation.hash` popstate event will be fired.
      // set `skipNextPopstateEvent` to skip that event.
      skipNextPopstateEvent = true;

      // don't reload worksheets if link with anchor was clicked (like #signUpModal)
      targetLocation.hash = '';
      return;
    }

    if (skipNextPopstateEvent) {
      skipNextPopstateEvent = false;
      return;
    }

    const url = targetLocation.pathname + targetLocation.search;
    updateCollections(url, {
      pushState: false
    });
  });
});
