import {URLS} from '../config';
import {createClassroom} from '../utils';

$(document).ready(function () {
  const $classroomHome = $('[data-selector="kidsacademy-com-static"]');
  if (!$classroomHome.length) return;

  const openClassroom = '[data-selector="open-classroom-btn"]';
  const goToTgOnlinePageBtn = '[data-selector="go-to-tg-online"]';

  $(document)
    .on('click', goToTgOnlinePageBtn, (e) => {
      const utm = $(e.target).attr('data-utm');
      window.location = `${URLS.TG_ONLINE}${utm ? utm : ''}`;
    })
    .on('click', openClassroom, (e) => {
      const utm = $(e.target).attr('data-utm');
      createClassroom({utm});
    });
});
