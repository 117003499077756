window.initRelatedWorksheetsSlider = (function () {
  return function () {
    const $worksheetsSlider = $('[data-selector="related-worksheets-slider"]');
    if(!$worksheetsSlider.length) return;
    const worksheetsCarouselSettings = {
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      swipeToSlide: true,
      centerPadding: '2%',
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: 1028,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 580,
        settings: {
          centerMode: true,
          slidesToShow: 1
        }
      }]
    };
    $worksheetsSlider.not('.slick-initialized').slick(worksheetsCarouselSettings);
  };
})();
window.initRelatedWorksheetsSlider();
